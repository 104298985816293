define("ember-svg-jar/inlined/check-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.957.52a1.396 1.396 0 012.01 1.938L10.675 18.314a1.4 1.4 0 01-2.112.11L1.167 10.75A1.4 1.4 0 013.18 8.808l6.388 6.627L23.957.52z\"/>",
    "attrs": {
      "width": "27",
      "height": "19",
      "viewBox": "0 0 27 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});