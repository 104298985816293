define("showbie-master-panel/components/topic-indicator/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <XIcon @symbol={{this.icon}} @width={{40}} @height={{40}} @title={{@label}} />
  */
  {
    "id": "ndmpPziR",
    "block": "[[[8,[39,0],null,[[\"@symbol\",\"@width\",\"@height\",\"@title\"],[[30,0,[\"icon\"]],40,40,[30,1]]],null]],[\"@label\"],false,[\"x-icon\"]]",
    "moduleName": "showbie-master-panel/components/topic-indicator/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});