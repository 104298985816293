define("ember-svg-jar/inlined/feedback-guide-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.171 6.265a.408.408 0 01.776 0l.814 2.458a2.042 2.042 0 001.296 1.296l2.458.815a.408.408 0 010 .775l-2.458.815a2.042 2.042 0 00-1.296 1.296l-.814 2.458a.408.408 0 01-.776 0l-.814-2.458a2.042 2.042 0 00-1.296-1.296l-2.458-.815a.408.408 0 010-.775l2.458-.815a2.042 2.042 0 001.296-1.296l.814-2.458zM5.242 1.49a.204.204 0 01.38 0l.646 1.626c.207.522.621.936 1.144 1.144l1.626.646a.204.204 0 010 .38l-1.626.645a2.042 2.042 0 00-1.144 1.144l-.646 1.627a.204.204 0 01-.38 0l-.646-1.627a2.042 2.042 0 00-1.144-1.144l-1.626-.646a.204.204 0 010-.38l1.626-.645a2.042 2.042 0 001.144-1.144l.646-1.627z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});