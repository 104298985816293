define("showbie-master-panel/components/master-panel-group/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      header=(component "ui-text" type="title" class="master-panel-group__header")
      list=(component "master-cell-list" class="master-panel-group__list")
    )
  }}
  */
  {
    "id": "49d+/wJh",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"list\"],[[50,\"ui-text\",0,null,[[\"type\",\"class\"],[\"title\",\"master-panel-group__header\"]]],[50,\"master-cell-list\",0,null,[[\"class\"],[\"master-panel-group__list\"]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "showbie-master-panel/components/master-panel-group/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});