define("ember-svg-jar/inlined/list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.5 22.25a2.25 2.25 0 012.245 2.096l.005.154-.005.154A2.25 2.25 0 118.5 22.25zm21.5 1.5a.75.75 0 010 1.5H14a.75.75 0 010-1.5h16zm-21.5 0a.75.75 0 10.743.852l.007-.102-.007-.102a.75.75 0 00-.743-.648zm0-8.5a2.25 2.25 0 012.245 2.096l.005.154-.005.154A2.25 2.25 0 118.5 15.25zm21.5 1.5a.75.75 0 010 1.5H14a.75.75 0 010-1.5h16zm-21.5 0a.75.75 0 10.743.852l.007-.102-.007-.102a.75.75 0 00-.743-.648zm0-8.5a2.25 2.25 0 012.245 2.096l.005.154-.005.154A2.25 2.25 0 118.5 8.25zm0 1.5a.75.75 0 10.743.852l.007-.102-.007-.102A.75.75 0 008.5 9.75zm21.5 0a.75.75 0 010 1.5H14a.75.75 0 010-1.5h16z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});