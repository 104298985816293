define("ember-svg-jar/inlined/close-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.785 4.947a.75.75 0 10-1.06-1.06l-3.82 3.819L5.18 3.98a.75.75 0 00-1.06 1.061l3.725 3.725-3.96 3.96a.75.75 0 101.061 1.06l3.96-3.959 4.052 4.053a.75.75 0 001.06-1.06L9.967 8.765l3.82-3.819z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});