define("showbie-master-panel/components/master-cell-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash cell=(component "master-cell"))}}
  */
  {
    "id": "s2izS8Gj",
    "block": "[[[18,1,[[28,[37,1],null,[[\"cell\"],[[50,\"master-cell\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "showbie-master-panel/components/master-cell-list/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});