define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.137 6.333a.625.625 0 01.092 1.244l-.092.006H6.625c-.537 0-.979.407-1.036.928l-.006.114v8.75c0 .537.407.979.928 1.036l.114.006h8.75c.537 0 .979-.407 1.036-.928l.006-.114v-3.512a.625.625 0 011.243-.092l.007.092v3.512a2.292 2.292 0 01-2.135 2.286l-.157.006h-8.75a2.292 2.292 0 01-2.286-2.135l-.006-.157v-8.75c0-1.213.943-2.206 2.135-2.286l.157-.006h3.512zm8.63-1.663h.013l.062.011.02.005.021.006a.607.607 0 01.1.038l.047.026a.627.627 0 01.295.443l.007.093v5a.625.625 0 01-1.243.092l-.007-.092V6.8l-7.474 7.475a.625.625 0 01-.957-.797l.073-.087L17.2 5.916h-3.492a.625.625 0 01-.618-.532l-.007-.092c0-.314.232-.574.533-.619l.092-.006h5l.07.004-.012-.001z\" fill=\"#009FE8\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});