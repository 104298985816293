define("showbie-live/reducers/assignment", ["exports", "showbie-live/reducers/store-reducer"], function (_exports, _storeReducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ASSIGNMENT_FOLDER_UPDATE = void 0;
  /**
   * @public
   * @scope {Showbie.Model.Assignment}
   * Triggered when an assignment is created
   */
  const ASSIGNMENT_FOLDER_UPDATE = 'ASSIGNMENT-FOLDER-UPDATE';

  /**
   * @public
   * @scope {Showbie.Model.Assignment}
   * Assignment subscription reducer
   */
  _exports.ASSIGNMENT_FOLDER_UPDATE = ASSIGNMENT_FOLDER_UPDATE;
  class AssignmentReducer extends _storeReducer.default {
    get registeredEvents() {
      return [ASSIGNMENT_FOLDER_UPDATE];
    }
    [ASSIGNMENT_FOLDER_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
  }
  _exports.default = AssignmentReducer;
});