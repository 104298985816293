define("ember-svg-jar/inlined/collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-collapse</title><path d=\"M18.055 13c.51 0 .945-.447.945-1 0-.544-.435-1-.945-1H5.945C5.452 11 5 11.456 5 12c0 .553.452 1 .945 1h12.11z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});