define("showbie-master-panel/components/cell-symbol/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      avatar=(component "ui-avatar" class="cell-symbol__avatar")
      icon=(component "x-icon" class="cell-symbol__icon")
      topic=(component "topic-indicator" class="cell-symbol__topic")
    )
  }}
  */
  {
    "id": "dOXtNn7U",
    "block": "[[[18,1,[[28,[37,1],null,[[\"avatar\",\"icon\",\"topic\"],[[50,\"ui-avatar\",0,null,[[\"class\"],[\"cell-symbol__avatar\"]]],[50,\"x-icon\",0,null,[[\"class\"],[\"cell-symbol__icon\"]]],[50,\"topic-indicator\",0,null,[[\"class\"],[\"cell-symbol__topic\"]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "showbie-master-panel/components/cell-symbol/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});