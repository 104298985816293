define("ember-svg-jar/inlined/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm7.106 5.61l.002.001a.73.73 0 01.07.951l-.071.082-7.924 7.912-.11.112a1.03 1.03 0 01-.02.02l-.686.682-.615-.61-.05-.042-.048-.045-3.76-3.758a.738.738 0 01-.003-1.04l.003-.004a.73.73 0 01.95-.07l.081.07 3.437 3.433 7.707-7.694a.735.735 0 011.037 0z\" fill=\"fillCurrent\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});