define("ember-svg-jar/inlined/folder-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M28 22a.75.75 0 01.75.75v2.5h2.5a.75.75 0 010 1.5h-2.5v2.5a.75.75 0 01-1.5 0v-2.5h-2.5a.75.75 0 010-1.5h2.5v-2.5A.75.75 0 0128 22zM16.581 9c1.213 0 2.187.829 2.423 2.063l.253.9 7.643.009c1.159 0 2.1.938 2.1 2.092V20h-1.5v-5.936a.596.596 0 00-.6-.592l-7.563-.001c-.815 0-1.42-.496-1.579-1.296l.719-.219-.723.201-.209-.751c-.093-.469-.383-.906-.964-.906H9.472a.97.97 0 00-.972.964v13.329c0 .324.388.707.716.707H22V27H9.216C8.056 27 7 25.948 7 24.793V11.464A2.47 2.47 0 019.472 9z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});