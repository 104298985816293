define("showbie-live/reducers/reducer", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Reducer {
    get version() {
      return 1;
    }
    get registeredEvents() {
      return [];
    }
    handle(data) {
      // @TODO use these to verify that the connection was successful, and that it continues to be functional
      // ... but just ignore them for now.
      if (data.ping || data.registration) {
        return;
      }
      if (this.shouldIgnoreEvent(data)) {
        return;
      }
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }
      this[data.event]?.(data, ...rest);
    }
    shouldIgnoreEvent(data) {
      if (this.version !== data.version) {
        (0, _debug.debug)(`Reducer encountered message with version ${data.version} but can only handle version ${this.version}`);
        return true;
      }
    }
  }
  _exports.default = Reducer;
});