define("ember-svg-jar/inlined/alert-info-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><ellipse cx=\"12.898\" cy=\"11.909\" rx=\"12\" ry=\"11.764\" fill=\"#007EC2\"/><path d=\"M12.357 8.162c-.39 0-.704-.115-.944-.345-.24-.23-.36-.525-.36-.885 0-.42.13-.765.39-1.035.27-.27.63-.405 1.08-.405.45 0 .795.115 1.034.345.24.23.36.525.36.885 0 .43-.134.78-.405 1.05-.27.26-.65.39-1.14.39h-.014zm-.914 2.865l-1.005-.285V9.617l3-.36h.03l.464.315v7.095l.9.135v.99h-4.32v-.99l.93-.135v-5.64z\" fill=\"#fff\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M.897.145h24v24h-24z\"/></clipPath></defs>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});