define("ember-svg-jar/inlined/feedback-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.55 11.56a6.85 6.85 0 01-.83.96l-.32.3c-2.89 2.5-7.52 2.87-9.29 2.9-.3.02-.5-.32-.32-.57l1.3-1.85a6.5 6.5 0 01-1.8-9.19 6.94 6.94 0 019.46-1.73 6.49 6.49 0 011.8 9.18z\"/>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});