define("showbie-master-panel/components/cell-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash body=(component "ui-text") meta=(component "ui-text" type="meta"))
  }}
  */
  {
    "id": "J0wOz9Yk",
    "block": "[[[18,1,[[28,[37,1],null,[[\"body\",\"meta\"],[[50,\"ui-text\",0,null,null],[50,\"ui-text\",0,null,[[\"type\"],[\"meta\"]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "showbie-master-panel/components/cell-content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});