define("ember-svg-jar/inlined/pencil-icon-sm-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h18v18H0z\"/></defs><g transform=\"translate(3 3)\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M16.426 3.118l-.76.76L14.11 2.32l.758-.76c.1-.1.262-.1.362 0l1.196 1.198c.1.1.1.261 0 .361zM1.9 16.098l.718-2.277.05-.05 1.558 1.558-.05.05-2.276.72zM13.049 3.38l1.558 1.56-9.321 9.327L3.73 12.71l9.319-9.329zm4.438-1.685L16.29.497c-.662-.663-1.82-.663-2.482 0L1.428 12.89a.747.747 0 00-.185.305l-1.208 3.83a.75.75 0 00.94.941l3.827-1.209c.115-.037.22-.1.305-.186l12.38-12.39c.332-.332.513-.773.513-1.243 0-.469-.181-.91-.513-1.242z\" mask=\"url(#b)\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});