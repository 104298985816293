define("ember-svg-jar/inlined/lock-unlocked-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.206 17.273a.712.712 0 00-.706.715v8.797a.713.713 0 00.706.716h13.588a.713.713 0 00.706-.716v-8.797a.712.712 0 00-.706-.716h-.485a.75.75 0 01-.75-.75v-3.42c0-2.886-2.146-5.282-4.99-5.573a5.5 5.5 0 00-1.134 0 5.57 5.57 0 00-4.694 3.767.727.727 0 00.097.662c.308.428 1.084.3 1.215-.116a4.15 4.15 0 013.11-2.825 4.197 4.197 0 011.671-.002c1.92.397 3.312 2.115 3.312 4.087v3.42a.75.75 0 01-.75.75h-10.19zM24.794 29H11.206A2.217 2.217 0 019 26.785v-8.797a2.216 2.216 0 012.206-2.216h9.44v-2.67a2.672 2.672 0 00-2.113-2.617 2.704 2.704 0 00-1.068.002 2.644 2.644 0 00-1.98 1.806c-.27.857-1.125 1.458-2.076 1.458a2.18 2.18 0 01-1.789-.918 2.24 2.24 0 01-.3-2.018c.885-2.622 3.224-4.498 5.96-4.777a6.843 6.843 0 011.444 0c3.61.369 6.335 3.406 6.335 7.064v2.687a2.22 2.22 0 011.941 2.2v8.796A2.217 2.217 0 0124.794 29z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});