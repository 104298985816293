define("ember-svg-jar/inlined/button-icon-backup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#FFF\"><path d=\"M16 8h-5v1h5v15H1V9h5V8H1c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1h15c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1z\"/><path d=\"M12.95 4.69L9.2.94l-.71-.7-.7.7-3.75 3.75.7.71L8 2.14V14h.99V2.15l3.25 3.25z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "25",
      "viewBox": "0 0 17 25"
    }
  };
  _exports.default = _default;
});