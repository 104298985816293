define("ember-svg-jar/inlined/link-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.318 14.458a6.006 6.006 0 018.485 0 5.953 5.953 0 011.533 2.623l-1.307 1.307a4.463 4.463 0 00-1.286-2.87 4.505 4.505 0 00-6.364 0l-2.829 2.83a4.505 4.505 0 000 6.363 4.505 4.505 0 006.364 0l.308-.308a7.535 7.535 0 002.151-.03l-1.398 1.399a6.006 6.006 0 01-8.485 0 6.006 6.006 0 010-8.485zm4.508-4.508c2.294-2.294 5.997-2.332 8.346-.125.032.026.066.052.095.082a.15.15 0 01.02.023 6.006 6.006 0 01.023 8.506h.001l-2.828 2.828a6.006 6.006 0 01-8.486 0l-.017-.02-.027-.024c-.03-.03-.055-.063-.084-.092a6.04 6.04 0 01-1.402-2.489l1.305-1.304a4.463 4.463 0 001.265 2.844c1.489 1.468 3.748 1.676 5.468.645l.164-.106.163-.112c.188-.137.376-.277.545-.447l1.166-1.165 1.663-1.664a4.503 4.503 0 00.02-6.339 4.504 4.504 0 00-6.34.02l-.308.308a7.535 7.535 0 00-2.15.03z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});