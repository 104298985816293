define("ember-svg-jar/inlined/triangle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 7.134a1 1 0 010 1.732L1.5 14.928a1 1 0 01-1.5-.866V1.938a1 1 0 011.5-.866L12 7.134z\" fill=\"#29B1F0\"/>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});