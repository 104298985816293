define("ember-svg-jar/inlined/refresh-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M26.88 16.26h-1.705c.457.998.718 2.102.718 3.269 0 4.352-3.54 7.892-7.893 7.892-4.351 0-7.893-3.54-7.893-7.892S13.65 11.636 18 11.636c.702 0 1.38.1 2.028.275l-3.25 3.25a.79.79 0 001.116 1.116l4.466-4.466a.79.79 0 000-1.116l-4.466-4.464a.788.788 0 10-1.116 1.116l2.872 2.87a9.37 9.37 0 00-1.65-.16 9.471 9.471 0 109.471 9.472c0-1.15-.216-2.25-.591-3.269\" fill=\"#009FE8\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});