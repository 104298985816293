define("ember-svg-jar/inlined/cell-icon-view-only-teachers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M23.99 7.042v.093a.943.943 0 01-.25.51c-.252.308-.527.6-.8.88a20.784 20.784 0 01-5.089 3.887 13.542 13.542 0 01-3.814 1.388 10.979 10.979 0 01-4.382-.062 16.489 16.489 0 01-6.793-3.563 18.515 18.515 0 01-2.6-2.53.973.973 0 010-1.305c.251-.307.526-.6.8-.88a20.761 20.761 0 015.089-3.886A13.491 13.491 0 019.965.185a11 11 0 014.382.062 16.491 16.491 0 016.793 3.564 18.4 18.4 0 012.6 2.53.942.942 0 01.251.509.234.234 0 010 .026.15.15 0 010 .022v.138zM12 1.948A5.047 5.047 0 1017.042 7 5.045 5.045 0 0012 1.948zm0 7.556a2.5 2.5 0 112.506-2.5A2.5 2.5 0 0112 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "14",
      "viewBox": "0 0 24 14"
    }
  };
  _exports.default = _default;
});