define("ember-svg-jar/inlined/pencil-icon-sm-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.375 5.73l-9.9 9.607 3.404 3.172 9.819-9.53zM4.59 16.563L3.528 19.85a.492.492 0 00.469.651c.05 0 .1 0 .15-.021l3.498-1.072-3.055-2.846zM20.55 5.081l-1.19-1.16c-.59-.56-1.54-.56-2.121 0l-.788.764 3.325 3.25.774-.753c.29-.28.45-.65.45-1.05s-.16-.77-.45-1.05\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});