define("showbie-master-panel/components/cell-element/component", ["exports", "@ember/template-factory", "@ember/component", "@ember/object"], function (_exports, _templateFactory, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  var _default = _component.default.extend({
    layout: (0, _templateFactory.createTemplateFactory)(
    /*
      {{yield}}
    */
    {
      "id": "7judeqAv",
      "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
      "moduleName": "/Users/keithkoshman/Dev/showbie/web/packages/showbie/showbie-master-panel/components/cell-element/component.js",
      "isStrictMode": false
    }),
    classNames: ['cell-element'],
    classNameBindings: ['modifierClass'],
    key: '',
    modifierClass: (0, _object.computed)('key', function () {
      let key = (0, _object.get)(this, 'key');
      return key ? `cell-element--${key}` : false;
    })
  });
  _exports.default = _default;
});