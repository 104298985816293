define("symbol-observable/index", ["exports", "symbol-observable/ponyfill"], function (_exports, _ponyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global window */

  var root;
  if (typeof self !== 'undefined') {
    root = self;
  } else if (typeof window !== 'undefined') {
    root = window;
  } else if (typeof global !== 'undefined') {
    root = global;
  } else if (typeof module !== 'undefined') {
    root = module;
  } else {
    root = Function('return this')();
  }
  var result = (0, _ponyfill.default)(root);
  var _default = result;
  _exports.default = _default;
});