define("torii/lib/required-property", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function requiredProperty() {
    return (0, _object.computed)(function (key) {
      throw new Error('Definition of property ' + key + ' by a subclass is required.');
    });
  }
  var _default = requiredProperty;
  _exports.default = _default;
});