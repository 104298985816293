define("showbie-paywall/helpers/paywall-price", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  /**
   * {{paywall-price}}
   * {{paywall-price "$1000"}}
   */
  var _default = _helper.default.extend({
    intl: (0, _service.inject)(),
    sales: (0, _service.inject)(),
    compute(_ref) {
      let [a1 = this.sales.soloProPriceDisplay] = _ref;
      let key = '{a1} / month, billed annually';
      return this.intl.t(key, {
        a1
      });
    }
  });
  _exports.default = _default;
});