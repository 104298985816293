define("ember-svg-jar/inlined/pin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.322 16.702l-3.12 3.12a.69.69 0 10.975.976l3.12-3.12a.69.69 0 10-.975-.976zm6.622-12.997a2.479 2.479 0 00-.718 1.922L9.292 8.56a6.798 6.798 0 00-4.835 1.344 1.181 1.181 0 00-.455.85 1.2 1.2 0 00.354.92l7.971 7.97c.223.234.527.355.84.355h.081c.334-.02.647-.192.85-.455a6.823 6.823 0 001.385-4.117 5.5 5.5 0 00-.041-.718l2.934-2.933c.061.01.122.01.183.01.617 0 1.244-.232 1.709-.708.971-.97.971-2.51.03-3.45l-3.925-3.924a2.437 2.437 0 00-3.429 0z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});