define("showbie-paywall/components/sbe-slideshow/component", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember/object", "showbie-paywall/components/sbe-slideshow/template"], function (_exports, _component, _computed, _service, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-actions-hash, ember/no-classic-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-get, ember/require-tagless-components */
  /**
   * sbe-slideshow
   * Component for displaying the paywall slideshow. Used exclusively in
   * sbe-paywall, and handles the concept of paging through slides, displaying the
   * current slide title and text, as well as setting up keyboard shortcuts
   * (left/right) for paging.
   *
   * Interfaces directly with the PaywallService for grabbing the `assembledSlides`
   * property that contains the correct slides to display (intro/no intro,
   * reordered slides, etc).
   */
  var _default = _component.default.extend({
    paywall: (0, _service.inject)(),
    layout: _template.default,
    classNames: ['sbe-slideshow'],
    classNameBindings: ['isIntroing:slideshow--intro'],
    /*
     * Class prefix for slideshow items.
     */
    classPrefix: 'slideshow__item--',
    /*
     * Index to start the slideshow at (1 by default)
     */
    currentIndex: 0,
    /*
     * Maxmimum of slides to display (must be an odd number)
     */
    maxSlides: 7,
    /*
     * Link text displayed if there is an intro slide, that starts the slideshow.
     */
    showMoreText: 'Learn More',
    /*
     * Whether or not to bypass checking paywall and always show the intro on load.
     */
    alwaysShowIntro: false,
    /*
     * Collection of slides, that can be overwritten if necessary.
     */
    slides: (0, _computed.reads)('paywall.assembledSlides'),
    /*
     * Title of the current slide.
     */
    currentTitle: (0, _computed.readOnly)('currentSlide.title'),
    /*
     * Text of the current slide.
     */
    currentText: (0, _computed.readOnly)('currentSlide.text'),
    /**
     * Replace variable within the current text string
     */
    currentTranslationKey: (0, _computed.readOnly)('currentSlide.translationKey'),
    /*
     * Whether or not to show the asterisk with a link to the feature article on learn.showbie.com
     */
    showAsterisk: (0, _computed.readOnly)('currentSlide.showAsterisk'),
    /*
     * Properties to tell if at either the beginning or end of the slideshow, as well as
     * if at the beginning /and/ showing intro slide.
     */
    beginning: (0, _computed.equal)('currentIndex', 0),
    currentSlide: (0, _object.computed)('slides.[]', 'currentIndex', function () {
      let slides = this.slides || [];
      let currentIndex = (0, _object.get)(this, 'currentIndex');
      return slides[currentIndex];
    }),
    end: (0, _object.computed)('currentIndex', 'slides.length', function () {
      let index = (0, _object.get)(this, 'currentIndex');
      let len = (0, _object.get)(this, 'slides.length');
      return index === len - 1;
    }),
    isIntroing: (0, _object.computed)('currentIndex', 'paywall.showIntro', 'alwaysShowIntro', function () {
      let showIntro = (0, _object.get)(this, 'paywall.showIntro');
      let {
        alwaysShowIntro,
        currentIndex
      } = (0, _object.getProperties)(this, 'alwaysShowIntro', 'currentIndex');
      return currentIndex === 0 && (showIntro || alwaysShowIntro);
    }),
    /*
     * Logic to slice slides into displayedSlides object.
     */
    displayedSlides: (0, _object.computed)('currentIndex', 'maxSlides', 'slides', function () {
      let currentIndex = (0, _object.get)(this, 'currentIndex');
      let padding = Math.floor((0, _object.get)(this, 'maxSlides') / 2);
      let slides = (0, _object.get)(this, 'slides');
      let start = currentIndex - padding;
      let end = currentIndex + padding + 1;
      let maxEnd = slides.length;
      return slides.slice(start > 0 ? start : 0, end < maxEnd ? end : maxEnd);
    }),
    /*
     * Augment the displayedSlides array objects to include a class that determines
     * whether or not it's previous or next to the current item in the slideshow, and
     * by how many objects.
     */
    augmentedSlides: (0, _object.computed)('currentIndex', 'classPrefix', 'maxSlides', 'slides.[]', function () {
      let slides = this.slides || [];
      let currentIndex = (0, _object.get)(this, 'currentIndex');
      let prefix = (0, _object.get)(this, 'classPrefix');
      let padding = Math.floor((0, _object.get)(this, 'maxSlides') / 2);
      slides.forEach(item => {
        /*
         * Difference (offset) of the item in the current iteration from the current
         * item in the slideshow.
         */
        let diff = item.index - currentIndex;
        let absDiff = Math.abs(diff);
        if (diff < 0) {
          // previous to the current item
          if (absDiff <= padding) {
            (0, _object.set)(item, 'classes', `${prefix}prev-${Math.abs(diff)}`);
          } else if (absDiff > padding) {
            (0, _object.set)(item, 'classes', `${prefix}off-left`);
          }
          (0, _object.set)(item, 'current', false);
        } else if (diff === 0) {
          // current item
          (0, _object.set)(item, 'classes', `${prefix}current`);
          (0, _object.set)(item, 'current', true);
        } else if (diff > 0) {
          if (absDiff <= padding) {
            (0, _object.set)(item, 'classes', `${prefix}next-${Math.abs(diff)}`);
          } else if (absDiff > padding) {
            (0, _object.set)(item, 'classes', `${prefix}off-right`);
          }
          (0, _object.set)(item, 'current', false);
        }
      });
      return slides;
    }),
    /*
     * Initializer for setup of the slideshow.
     */
    didInsertElement() {
      this._super(...arguments);
    },
    actions: {
      nextSlide() {
        if (!this.end) {
          this.incrementProperty('currentIndex');
        }
      },
      prevSlide() {
        if (!this.beginning) {
          this.decrementProperty('currentIndex');
        }
      },
      showFeaturesArticle() {
        window.open('https://support.showbie.com/getting-started/showbie-cross-platform-features', '_blank', 'noopener');
      }
    }
  });
  _exports.default = _default;
});