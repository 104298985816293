define("ember-svg-jar/inlined/pencil-icon-lg-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M25.426 12.118l-.76.76-1.556-1.559.758-.76c.1-.1.262-.1.362 0l1.196 1.198c.1.1.1.261 0 .361zM10.9 25.098l.718-2.277.05-.05 1.558 1.558-.05.05-2.276.72zM22.049 12.38l1.558 1.56-9.321 9.327-1.556-1.558 9.319-9.329zm4.438-1.685L25.29 9.497c-.662-.663-1.82-.663-2.482 0L10.428 21.89a.747.747 0 00-.185.305l-1.208 3.83a.75.75 0 00.94.941l3.827-1.209c.115-.037.22-.1.305-.186l12.38-12.39c.332-.332.513-.773.513-1.243 0-.469-.181-.91-.513-1.242z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});