/* eslint-env browser */
self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember.ember-global' },
    { handler: 'silence', matchId: 'ember.built-in-components.import' },
    { handler: 'silence', matchId: 'ember.globals-resolver' },
    { handler: 'silence', matchId: 'ember.component.reopen' },
    { handler: 'silence', matchId: 'ember.built-in-components.reopen' },
    { handler: 'silence', matchId: 'array-observers' },
    { handler: 'silence', matchId: 'this-property-fallback' },
    { handler: 'silence', matchId: 'ember-test-selectors.auto-binding' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'silence', matchId: 'showbie.ui.nav-header' },
    { handler: 'silence', matchId: 'manager-capabilities.modifiers-3-13' },
    { handler: 'silence', matchId: 'ember-component.is-visible' },
    { handler: 'silence', matchId: 'route-render-template' },
    { handler: 'silence', matchId: 'ember-metal.computed-deep-each' },
    { handler: 'silence', matchId: 'routing.transition-methods' },
    { handler: 'silence', matchId: 'ember-component.send-action' },
    {
      handler: 'silence',
      matchId: 'ember.built-in-components.legacy-arguments',
    },
    {
      handler: 'silence',
      matchId: 'ember-engines.deprecation-router-service-from-host',
    },
    { handler: 'silence', matchId: 'showbie.ui.text-button' },
    { handler: 'silence', matchId: 'showbie.deprecate-class-code' },
    { handler: 'silence', matchId: 'showbie-ui.l-navbar' },
    { handler: 'silence', matchId: 'showbie.flexible-text-input' },
    { handler: 'silence', matchId: 'ember-data:legacy-test-helper-support' },
    { handler: 'silence', matchId: 'showbie-ui.ui-nav-header' },
    { handler: 'silence', matchId: 'ember-data:-legacy-test-registrations' },
    {
      handler: 'silence',
      matchId: 'showbie-utils.device-service.isFlashEnabled',
    },
    {
      handler: 'silence',
      matchId: 'showbie-utils.device-service.isTouchDevice',
    },
  ],
};
