define("ember-svg-jar/inlined/check-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.458 5.5a.75.75 0 010 1.06l-7.036 7.036-3.903-3.903a.75.75 0 111.06-1.061l2.843 2.843 5.975-5.976a.75.75 0 011.061 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});