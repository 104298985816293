define("audio/components/audio-recorder/level-meter/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="level-meter__level"
    style={{this.levelStyle}}
    data-test-level-meter
  ></div>
  <div class="level-meter__mask"></div>
  */
  {
    "id": "AHr+IykN",
    "block": "[[[10,0],[14,0,\"level-meter__level\"],[15,5,[30,0,[\"levelStyle\"]]],[14,\"data-test-level-meter\",\"\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"level-meter__mask\"],[12],[13]],[],false,[]]",
    "moduleName": "audio/components/audio-recorder/level-meter/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});