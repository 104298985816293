define("ember-svg-jar/inlined/download-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M26.75 27.5a.75.75 0 010 1.5h-17a.75.75 0 010-1.5h17zM19.45 7a2.754 2.754 0 012.75 2.75V14h4.05a.75.75 0 01.549 1.261l-8 8.6a.747.747 0 01-1.098 0l-8-8.6A.75.75 0 0110.25 14h4.05V9.75A2.754 2.754 0 0117.05 7h2.4zm0 1.5h-2.4c-.69 0-1.25.561-1.25 1.25v5a.75.75 0 01-.75.75h-3.078l6.278 6.749 6.279-6.749H21.45a.75.75 0 01-.75-.75v-5c0-.689-.561-1.25-1.25-1.25z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});