define("ember-svg-jar/inlined/chevron-right-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.263 5.677a1 1 0 011.32-1.497l.094.083 6.53 6.53a1 1 0 01.083 1.32l-.083.094-6.5 6.5a1 1 0 01-1.497-1.32l.083-.094 5.792-5.793-5.822-5.823z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});