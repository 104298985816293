define("ember-svg-jar/inlined/copy-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 20H8a2 2 0 01-2-2V7a2 2 0 012-2h11a2 2 0 012 2v11a2 2 0 01-2 2zm1-13a1 1 0 00-1-1H8a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1V7zm-6 9h-1v-3h-3v-1h3V9h1v3h3v1h-3v3zM1 13v11a1 1 0 001 1h11a1 1 0 001-1v-3h1v3a2 2 0 01-2 2H2a2 2 0 01-2-2V13a2 2 0 012-2h3v1H2a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "31",
      "viewBox": "0 0 21 31"
    }
  };
  _exports.default = _default;
});