define("showbie-master-panel/helpers/permission-for", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils", "@ember/component/helper"], function (_exports, _computed, _object, _service, _utils, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get */
  var _default = _helper.default.extend({
    currentUserService: (0, _service.inject)('current-user'),
    currentUser: (0, _computed.readOnly)('currentUserService.user'),
    compute(_ref, _ref2) {
      let [model] = _ref;
      let {
        user = (0, _object.get)(this, 'currentUser')
      } = _ref2;
      model = this._getResolved(model);
      if ((0, _utils.isNone)(model)) {
        return null;
      }

      // permissions _should be_ side-loaded with classes, but just in case...
      let permissions = (0, _utils.isPresent)(model.hasMany('permissions').value()) ? model.hasMany('permissions').value() : (0, _object.get)(model, 'permissions');
      permissions = this._getResolved(permissions);
      if ((0, _utils.isNone)(permissions)) {
        return null;
      }
      return permissions.findBy('user.id', (0, _object.get)(user, 'id'));
    },
    _getResolved(promiseProxy) {
      let isFulfilled = (0, _object.get)(promiseProxy, 'isFulfilled');
      if ((0, _utils.isPresent)(isFulfilled)) {
        if (!isFulfilled) {
          promiseProxy.then(() => this.recompute());
          return null;
        }
        promiseProxy = (0, _object.get)(promiseProxy, 'content');
      }
      return promiseProxy;
    }
  });
  _exports.default = _default;
});