define("ember-svg-jar/inlined/overview-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.5 13V9.5h5.25c.41 0 .75.34.75.75V13h-6zm6 12.75c0 .41-.34.75-.75.75H22.5v-12h6v11.25zm-21 0V14.5h6v12H8.25a.75.75 0 01-.75-.75zM8.25 9.5h5.25V13h-6v-2.75c0-.41.34-.75.75-.75zm6.75 17h6v-12h-6v12zM15 13h6V9.5h-6V13zm12.75-5H8.25A2.25 2.25 0 006 10.25v15.5A2.25 2.25 0 008.25 28h19.5A2.25 2.25 0 0030 25.75v-15.5A2.25 2.25 0 0027.75 8z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});