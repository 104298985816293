define("audio/components/audio-recorder/recording-time/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="recording-time__activity" data-test-recording-indicator>
    <XIcon @symbol="audio-activity" @height="12px" @width="12px" />
  </span>
  <span class="recording-time__container">
    {{#if (or @showPosition @isMini)}}
      <span
        class="recording-time__position"
        data-test-recording-position
      >{{duration this.audioPosition}}</span>
    {{/if}}
    {{#unless @isMini}}
      <span class="recording-time__duration" data-test-recording-duration>
        {{~#if @isSaving~}}
          {{t "Saving…"}}
        {{~else~}}
          {{duration this.audioDuration roundUp=this.roundUp}}
        {{~/if~}}
      </span>
    {{/unless}}
  </span>
  */
  {
    "id": "4LVJD7JZ",
    "block": "[[[10,1],[14,0,\"recording-time__activity\"],[14,\"data-test-recording-indicator\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@symbol\",\"@height\",\"@width\"],[\"audio-activity\",\"12px\",\"12px\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"recording-time__container\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,1],[30,2]],null],[[[1,\"    \"],[10,1],[14,0,\"recording-time__position\"],[14,\"data-test-recording-position\",\"\"],[12],[1,[28,[35,3],[[30,0,[\"audioPosition\"]]],null]],[13],[1,\"\\n\"]],[]],null],[41,[51,[30,2]],[[[1,\"    \"],[10,1],[14,0,\"recording-time__duration\"],[14,\"data-test-recording-duration\",\"\"],[12],[41,[30,3],[[[1,[28,[35,5],[\"Saving…\"],null]]],[]],[[[1,[28,[35,3],[[30,0,[\"audioDuration\"]]],[[\"roundUp\"],[[30,0,[\"roundUp\"]]]]]]],[]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@showPosition\",\"@isMini\",\"@isSaving\"],false,[\"x-icon\",\"if\",\"or\",\"duration\",\"unless\",\"t\"]]",
    "moduleName": "audio/components/audio-recorder/recording-time/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});