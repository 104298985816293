define("audio/components/audio-recorder/recording-time/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "moment", "audio/components/audio-recorder/recording-time/template"], function (_exports, _component, _object, _computed, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  /**
   * @module audio-recorder
   */
  /**
   * @class RecordingTime
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['recording-time'],
    classNameBindings: ['indicateActivity:is-indicating', 'approachingMaxDuration:is-warning'],
    /** @type {number} Current playback position. */
    position: 0,
    /** @type {number} Current recording length. */
    duration: 0,
    /** @type {boolean} Whether the activity spinner is shown. */
    indicateActivity: (0, _computed.or)('isPlaying', 'isRecording', 'isSaving'),
    audioPosition: (0, _object.computed)('position', function () {
      let position = (0, _object.get)(this, 'position');
      if (position > 0) {
        return _moment.default.duration(position, 'milliseconds');
      }
      return;
    }),
    audioDuration: (0, _object.computed)('duration', 'durationRemaining', 'approachingMaxDuration', 'isRecording', function () {
      const duration = (0, _object.get)(this, 'duration');
      const durationRemaining = Math.max((0, _object.get)(this, 'durationRemaining'), 0);
      if ((0, _object.get)(this, 'approachingMaxDuration') && (0, _object.get)(this, 'isRecording')) {
        /* eslint ember/no-side-effects: "warn" */
        (0, _object.set)(this, 'roundUp', true);
        return _moment.default.duration(durationRemaining, 'milliseconds');
      } else {
        /* eslint ember/no-side-effects: "warn" */
        (0, _object.set)(this, 'roundUp', false);
        return _moment.default.duration(duration, 'milliseconds');
      }
    })
  });
  _exports.default = _default;
});