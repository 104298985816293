define("showbie-live/reducers/app", ["exports", "@ember/object", "showbie-live/reducers/store-reducer", "showbie/actions", "showbie-utils/constants"], function (_exports, _object, _storeReducer, _actions, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOPIC_UPDATE = _exports.TOPIC_DELETE = _exports.TOPIC_CUSTOM_SORT_ACTIVATED = _exports.TOPIC_ADD = _exports.MEETING_START = _exports.MEETING_END = _exports.LICENCE_UPDATE = _exports.INTEGRATION_UNLINK = _exports.INTEGRATION_LINK = _exports.FRESHNESS = _exports.FORMATIVE_ASSESSMENT_START = _exports.FORMATIVE_ASSESSMENT_END = _exports.CLASS_UPDATE = _exports.CLASS_PERMISSION_UPDATE = _exports.CLASS_PERMISSION_ADD = _exports.CLASS_INTEGRATION_UPDATE = _exports.CLASS_INTEGRATION_DELETE = _exports.CLASS_INTEGRATION_ADD = _exports.CLASS_DELETE = _exports.CLASS_CUSTOM_SORT_ACTIVATED = _exports.CLASS_ADD = _exports.CHANNEL_UPDATE = _exports.CHANNEL_PERMISSION_UPDATE = _exports.CHANNEL_PERMISSION_ADD = _exports.CHANNEL_INVITE = _exports.CHANNEL_DELETE = _exports.CHANNEL_ADD = _exports.ASSIGNMENT_UPDATE = _exports.ASSIGNMENT_SCHEDULE_UPDATE = _exports.ASSIGNMENT_SCHEDULE_DELETE = _exports.ASSIGNMENT_SCHEDULE_ADD = _exports.ASSIGNMENT_DELETE = _exports.ASSIGNMENT_ADD = void 0;
  /* eslint-disable ember/classic-decorator-hooks, ember/no-get */

  /**
   * @public
   * the current session user is upgraded/downgraded, total active assignment count increased/decreased, user added/removed from a school
   */
  const LICENCE_UPDATE = 'LICENCE-UPDATE';

  /**
   * @public
   * a class is added
   */
  _exports.LICENCE_UPDATE = LICENCE_UPDATE;
  const CLASS_ADD = 'CLASS-ADD';

  /**
   * @public
   *  class update or archive/restore is performed
   */
  _exports.CLASS_ADD = CLASS_ADD;
  const CLASS_UPDATE = 'CLASS-UPDATE';

  /**
   * @public
   * a class is deleted
   */
  _exports.CLASS_UPDATE = CLASS_UPDATE;
  const CLASS_DELETE = 'CLASS-DELETE';

  /**
   * @public
   * a class-permission is added
   */
  _exports.CLASS_DELETE = CLASS_DELETE;
  const CLASS_PERMISSION_ADD = 'CLASS-PERMISSION-ADD';

  /**
   * @public
   *  class-permission update or archive/restore is performed
   */
  _exports.CLASS_PERMISSION_ADD = CLASS_PERMISSION_ADD;
  const CLASS_PERMISSION_UPDATE = 'CLASS-PERMISSION-UPDATE';

  /**
   * @public
   * a class-integration is added
   */
  _exports.CLASS_PERMISSION_UPDATE = CLASS_PERMISSION_UPDATE;
  const CLASS_INTEGRATION_ADD = 'CLASS-INTEGRATION-ADD';

  /**
   * @public
   *  class-integration update is performed; generally changing the class relationship.
   */
  _exports.CLASS_INTEGRATION_ADD = CLASS_INTEGRATION_ADD;
  const CLASS_INTEGRATION_UPDATE = 'CLASS-INTEGRATION-UPDATE';

  /**
   * @public
   * a class-integration is deleted
   */
  _exports.CLASS_INTEGRATION_UPDATE = CLASS_INTEGRATION_UPDATE;
  const CLASS_INTEGRATION_DELETE = 'CLASS-INTEGRATION-DELETE';

  /**
   * @public
   * a class sort order is changed to custom (which also affects assignments in it)
   */
  _exports.CLASS_INTEGRATION_DELETE = CLASS_INTEGRATION_DELETE;
  const CLASS_CUSTOM_SORT_ACTIVATED = 'CLASS-CUSTOM-SORT-ACTIVATED';

  /**
   * @public
   * a channel is created
   */
  _exports.CLASS_CUSTOM_SORT_ACTIVATED = CLASS_CUSTOM_SORT_ACTIVATED;
  const CHANNEL_ADD = 'CHANNEL-ADD';

  /**
   * @public
   * a channel update or archive/restore is performed
   */
  _exports.CHANNEL_ADD = CHANNEL_ADD;
  const CHANNEL_UPDATE = 'CHANNEL-UPDATE';

  /**
   * @public
   * a channel is deleted
   */
  _exports.CHANNEL_UPDATE = CHANNEL_UPDATE;
  const CHANNEL_DELETE = 'CHANNEL-DELETE';

  /**
   * @public
   *  a channel invite is created */
  _exports.CHANNEL_DELETE = CHANNEL_DELETE;
  const CHANNEL_INVITE = 'CHANNEL-INVITE';

  /**
   * @public
   * a user joins a channel
   */
  _exports.CHANNEL_INVITE = CHANNEL_INVITE;
  const CHANNEL_PERMISSION_ADD = 'CHANNEL-PERMISSION-ADD';

  /**
   * @public
   * a permission is updated (block, unblock, set ownership, revoke ownership, ...)
   */
  _exports.CHANNEL_PERMISSION_ADD = CHANNEL_PERMISSION_ADD;
  const CHANNEL_PERMISSION_UPDATE = 'CHANNEL-PERMISSION-UPDATE';

  /**
   * @public
   * a topic (class folder) is created
   */
  _exports.CHANNEL_PERMISSION_UPDATE = CHANNEL_PERMISSION_UPDATE;
  const TOPIC_ADD = 'TOPIC-ADD';

  /**
   * @public
   * a topic (class folder) update or archive/restore is performed
   */
  _exports.TOPIC_ADD = TOPIC_ADD;
  const TOPIC_UPDATE = 'TOPIC-UPDATE';

  /**
   * @public
   * a topic (class folder) is deleted
   */
  _exports.TOPIC_UPDATE = TOPIC_UPDATE;
  const TOPIC_DELETE = 'TOPIC-DELETE';

  /**
   * @public
   * a topic sort order is changed to custom (which also affects assignments in it)
   */
  _exports.TOPIC_DELETE = TOPIC_DELETE;
  const TOPIC_CUSTOM_SORT_ACTIVATED = 'TOPIC-CUSTOM-SORT-ACTIVATED';

  /**
   * @public
   * content within a class or channel has been updated
   */
  _exports.TOPIC_CUSTOM_SORT_ACTIVATED = TOPIC_CUSTOM_SORT_ACTIVATED;
  const FRESHNESS = 'FRESHNESS';

  /**
   * @public
   * Triggered when an assignment schedule is created
   */
  _exports.FRESHNESS = FRESHNESS;
  const ASSIGNMENT_SCHEDULE_ADD = 'ASSIGNMENT-SCHEDULE-ADD';

  /**
   * @public
   * Triggered when an assignment schedule is updated
   */
  _exports.ASSIGNMENT_SCHEDULE_ADD = ASSIGNMENT_SCHEDULE_ADD;
  const ASSIGNMENT_SCHEDULE_UPDATE = 'ASSIGNMENT-SCHEDULE-UPDATE';

  /**
   * @public
   * Triggered when an assignment schedule is updated
   */
  _exports.ASSIGNMENT_SCHEDULE_UPDATE = ASSIGNMENT_SCHEDULE_UPDATE;
  const ASSIGNMENT_SCHEDULE_DELETE = 'ASSIGNMENT-SCHEDULE-DELETE';

  /**
   * @public
   * Triggered when an assignment is created
   */
  _exports.ASSIGNMENT_SCHEDULE_DELETE = ASSIGNMENT_SCHEDULE_DELETE;
  const ASSIGNMENT_ADD = 'ASSIGNMENT-ADD';

  /**
   * @public
   * Triggered when an assignment update or archive/restore is performed or when an assignment schedule is processed through our scheduler and changes the assignments student access level
   */
  _exports.ASSIGNMENT_ADD = ASSIGNMENT_ADD;
  const ASSIGNMENT_UPDATE = 'ASSIGNMENT-UPDATE';

  /**
   * @public
   * Triggered when an assignment is deleted
   */
  _exports.ASSIGNMENT_UPDATE = ASSIGNMENT_UPDATE;
  const ASSIGNMENT_DELETE = 'ASSIGNMENT-DELETE';

  /**
   * @public
   * an integration has been added to the current user's account
   */
  _exports.ASSIGNMENT_DELETE = ASSIGNMENT_DELETE;
  const INTEGRATION_LINK = 'INTEGRATION-LINK';

  /**
   * @public
   * an integration has been removed from the current user's account
   */
  _exports.INTEGRATION_LINK = INTEGRATION_LINK;
  const INTEGRATION_UNLINK = 'INTEGRATION-UNLINK';

  /**
   * @public
   * Triggered when a video chat meeting has started.
   */
  _exports.INTEGRATION_UNLINK = INTEGRATION_UNLINK;
  const MEETING_START = 'MEETING-START';

  /**
   * @public
   * Triggered when a video chat meeting has ended.
   */
  _exports.MEETING_START = MEETING_START;
  const MEETING_END = 'MEETING-END';

  /**
   * @public
   * Triggered when an assessment has started.
   */
  _exports.MEETING_END = MEETING_END;
  const FORMATIVE_ASSESSMENT_START = 'FORMATIVE-ASSESSMENT-START';

  /**
   * @public
   * Triggered when an assessment has ended.
   */
  _exports.FORMATIVE_ASSESSMENT_START = FORMATIVE_ASSESSMENT_START;
  const FORMATIVE_ASSESSMENT_END = 'FORMATIVE-ASSESSMENT-END';

  /**
   * @public
   * App reducer
   */
  _exports.FORMATIVE_ASSESSMENT_END = FORMATIVE_ASSESSMENT_END;
  class AppReducer extends _storeReducer.default {
    constructor(store, freshnessService, reduxService, scheduleManagerService) {
      super(store);
      this.freshnessService = freshnessService;
      this.reduxService = reduxService;
      this.scheduleManagerService = scheduleManagerService;
    }
    shouldIgnoreEvent(data) {
      // allow any version of FRESHNESS or ASSIGNMENT_DELETE/ASSIGNMENT_UPDATE events to be processed.
      if (data.event === FRESHNESS || data.event === ASSIGNMENT_DELETE || data.event === ASSIGNMENT_UPDATE) {
        return false;
      }
      return super.shouldIgnoreEvent(data);
    }
    [CLASS_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [CLASS_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
    [CLASS_PERMISSION_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [CLASS_PERMISSION_UPDATE](message, store) {
      store.pushPayload(message.data);
      let classPermission = store.peekRecord('classPermission', message.data.classPermission.id);
      classPermission.onPermissionsUpdated();
    }
    [CLASS_INTEGRATION_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [CLASS_INTEGRATION_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
    [CLASS_INTEGRATION_DELETE](message, store) {
      unloadRecord(store, 'class-integration', (0, _object.get)(message, 'objectId'));
    }
    [CLASS_DELETE](message, store) {
      unloadRecord(store, 'class', (0, _object.get)(message, 'objectId'));
    }
    [CLASS_CUSTOM_SORT_ACTIVATED](message, store) {
      store.pushPayload(message.data);
    }
    [CHANNEL_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [CHANNEL_DELETE](message, store) {
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      unloadRecord(store, 'channel', (0, _object.get)(message, 'objectId'));
    }
    [CHANNEL_UPDATE](message, store) {
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      store.pushPayload(message.data);
    }
    [CHANNEL_INVITE](message, store) {
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      store.pushPayload(message.data);
    }
    [CHANNEL_PERMISSION_ADD](message, store) {
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      store.pushPayload(message.data);
    }
    [CHANNEL_PERMISSION_UPDATE](message, store) {
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      store.pushPayload(message.data);
      let {
        channelPermission,
        users
      } = message.data;
      if (channelPermission.status === 'B') {
        // if they're block, unload the users posts
        store.peekAll('channel-post').filterBy('user.id', users[0].id).forEach(post => store.unloadRecord(post));
      }
      // TODO reload class discussion posts once we figure out how to reload the paginated posts
    }

    [TOPIC_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [TOPIC_UPDATE](message, store) {
      if (message.data.topic && 'meta' in message.data.topic) {
        // empty incoming meta; keep meta in the store the same
        delete message.data.topic.meta;
      }
      store.pushPayload(message.data);
    }
    [TOPIC_DELETE](message, store) {
      unloadRecord(store, 'topic', (0, _object.get)(message, 'objectId'));
    }
    [TOPIC_CUSTOM_SORT_ACTIVATED](message, store) {
      store.pushPayload(message.data);
    }
    [FRESHNESS](message, store) {
      // ignore V1 freshness
      if (message.version === 1) return;
      let freshnessService = (0, _object.get)(this, 'freshnessService');
      freshnessService.refresh();
      let payload = message.data.freshness;
      let modelName = payload.resourceType;
      let freshnessProperty = 'meta.fresh';
      if (modelName === 'class-discussion') {
        modelName = 'class';
        freshnessProperty = 'meta.freshDiscussion';
      }
      if (modelName === 'channel-post') {
        freshnessProperty = 'meta.fresh';
      }
      if (modelName === 'class-discussion-post') {
        modelName = 'channel-post';
        freshnessProperty = 'meta.fresh';
      }
      if (modelName === 'folder') {
        freshnessProperty = 'fresh';
      }
      let model = store.peekRecord(modelName, payload.resource);
      if (model) {
        (0, _object.trySet)(model, freshnessProperty, payload.fresh);
      }
    }
    [ASSIGNMENT_SCHEDULE_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [ASSIGNMENT_SCHEDULE_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
    [ASSIGNMENT_SCHEDULE_DELETE](message, store) {
      unloadRecord(store, 'assignment-schedule', (0, _object.get)(message, 'objectId'));
    }
    [ASSIGNMENT_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [ASSIGNMENT_UPDATE](message, store) {
      if (message.version !== _constants.ASSIGNMENT_UPDATE_VERSION) return;
      if (message.data.assignment && 'meta' in message.data.assignment) {
        // empty incoming meta; keep meta in the store the same
        delete message.data.assignment.meta;
      }
      store.pushPayload(message.data);

      // believe it or not, `ASSIGNMENT_UPDATE` doesn't always include an assignment
      if (message.data.assignment) {
        this.reduxService.dispatch((0, _actions.updateAssignments)([message.data.assignment]));
      }
    }
    [ASSIGNMENT_DELETE](message, store) {
      if (message.version !== _constants.ASSIGNMENT_DELETE_VERSION) return;
      this.scheduleManagerService.removeAssignment(store.peekRecord('assignment', (0, _object.get)(message, 'objectId')));
      unloadRecord(store, 'assignment', (0, _object.get)(message, 'objectId'));
    }
    [LICENCE_UPDATE](message, store) {
      store.pushPayload(message.data);
      /* Refresh the classes as a licence update will affect hasPremiumOwner property */
      store.findAll('class');
    }
    [INTEGRATION_LINK](message, store) {
      store.pushPayload(message.data);
    }
    [INTEGRATION_UNLINK](message, store) {
      let model = store.peekRecord('integration', (0, _object.get)(message, 'objectId'));
      model.onUnlinkAccount();

      /*
      Unload all class-integrations.
      `/classes` payload contains any class-integrations created by other teachers
      so we reload that to cover co-teacher situations.
       */
      store.findAll('class');
    }

    /**
     * Push the meeting payload into the store and also manually set
     * the parent relationship based on the `option` hash in the meeting.
     * This step is required because we don't have a proper relationship
     * because the parent can be a folder or a channel which are not
     * related models.
     *
     * @param {object} message
     * @param {DS.Store} store
     */
    [MEETING_START](message, store) {
      store.pushPayload(message.data);
      let meeting = store.peekRecord('meeting', message.data.meeting.id);
      let {
        container,
        id
      } = message.data.meeting.options;
      let parent = peekVideoChatContainer(store, container, id); // container can be a `folder` or `channel`
      (0, _object.trySet)(parent, 'meeting', meeting);
    }

    /**
     * Unload the meeting record and reset the parent model's relationship.
     *
     * @param {object} message
     * @param {DS.Store} store
     */
    [MEETING_END](message, store) {
      unloadRecord(store, 'meeting', message.data.meeting.id);
      let {
        container,
        id
      } = message.data.meeting.options;
      let parent = peekVideoChatContainer(store, container, id); // container can be a `folder` or `channel`
      (0, _object.trySet)(parent, 'meeting', null);
    }
    [FORMATIVE_ASSESSMENT_START](message, store) {
      store.pushPayload(message.data);
      let assessment = store.peekRecord('formativeAssessment', message.data.formativeAssessment.formativeAssessmentId);
      let container = peekFormativeAssessmentContainer(store, assessment.containerType, assessment.containerId);
      (0, _object.trySet)(container, 'formativeAssessment', assessment);
    }
    [FORMATIVE_ASSESSMENT_END](message, store) {
      let {
        formativeAssessment
      } = message.data;
      unloadRecord(store, 'formativeAssessment', formativeAssessment.formativeAssessmentId);
      let container = peekFormativeAssessmentContainer(store, formativeAssessment.containerType, formativeAssessment.containerId);
      (0, _object.trySet)(container, 'formativeAssessment', null);
    }
  }

  /**
   * Unload a local record from the Ember Data store.
   * @param  {DS.Store} store     Reference to the ED Store
   * @param  {String} modelName
   * @param  {String} id
   * @return {void}
   */
  _exports.default = AppReducer;
  function unloadRecord(store, modelName, id) {
    if (!modelName || !id) return;
    let model = store.peekRecord(modelName, id);
    if (model) {
      store.unloadRecord(model);
    }
  }

  /**
   * Find the parent container for a video chat.
   * This can be a channel or a folder model.
   *
   * Note: Class Discussion is a channel but the `meeting` relationship
   * actually exists on the class, not the channel. In this case, we simply
   * look for a class model with the same ID and if we find one we return
   * that instead. This is possible because the channel of a class discussion
   * has the same ID as the class itself.
   *
   * @param {DS.Store} store
   * @param {string} modelName Should be`channel` or `folder`
   * @param {string} id
   * @return {DS.Model} A Channel, Folder, or Class model
   */
  function peekVideoChatContainer(store, modelName, id) {
    if (modelName === 'channel') {
      let klass = store.peekRecord('class', id);
      if (klass) {
        return klass;
      }
    }
    return store.peekRecord(modelName, id);
  }
  function peekFormativeAssessmentContainer(store, modelName, id) {
    if (modelName === 'group') {
      let klass = store.peekRecord('class', id);
      if (klass) {
        return klass;
      }
    }
    return store.peekRecord(modelName, id);
  }
});