define("audio/components/x-audio/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.error}}
    <div class="post__audio__comment--inner">
      <div class="post__audio__comment--controls">
        <span class="voice-note-control--icon" {{action "toggle"}}></span>
        {{this.currentTime}}
      </div>
  
      <div class="post__audio__comment--scrubber">
        <div class="seek">
          <span class="seek--track" data-audio-seek></span>
          <span class="seek--progress" style="{{this.positionStyleWidth}}"></span>
          <span class="seek--thumb" style="{{this.positionStyleLeft}}"></span>
        </div>
      </div>
    </div>
  {{else}}
    <div class="post__audio__comment--inner-unsupported">
      {{t "Unsupported voice note format."}}
      <a
        href="https://support.showbie.com/showbie-faqs-and-troubleshooting/why-cant-i-record-voice-notes-on-the-web-app"
        target="_blank"
        rel="noopener noreferrer"
      >{{t "Learn more…"}}</a>
    </div>
  {{/unless}}
  
  <audio style="display:none;" data-test-audio-element>
    <source src={{@url}} type={{this.type}} />
  </audio>
  */
  {
    "id": "Ibg/pgK1",
    "block": "[[[41,[51,[30,0,[\"error\"]]],[[[1,\"  \"],[10,0],[14,0,\"post__audio__comment--inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"post__audio__comment--controls\"],[12],[1,\"\\n      \"],[11,1],[24,0,\"voice-note-control--icon\"],[4,[38,1],[[30,0],\"toggle\"],null],[12],[13],[1,\"\\n      \"],[1,[30,0,[\"currentTime\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"post__audio__comment--scrubber\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"seek\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"seek--track\"],[14,\"data-audio-seek\",\"\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"seek--progress\"],[15,5,[30,0,[\"positionStyleWidth\"]]],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"seek--thumb\"],[15,5,[30,0,[\"positionStyleLeft\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"post__audio__comment--inner-unsupported\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"Unsupported voice note format.\"],null]],[1,\"\\n    \"],[10,3],[14,6,\"https://support.showbie.com/showbie-faqs-and-troubleshooting/why-cant-i-record-voice-notes-on-the-web-app\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[28,[35,2],[\"Learn more…\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[10,\"audio\"],[14,5,\"display:none;\"],[14,\"data-test-audio-element\",\"\"],[12],[1,\"\\n  \"],[10,\"source\"],[15,\"src\",[30,1]],[15,4,[30,0,[\"type\"]]],[12],[13],[1,\"\\n\"],[13]],[\"@url\"],false,[\"unless\",\"action\",\"t\"]]",
    "moduleName": "audio/components/x-audio/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});