define("audio/components/audio-recorder/button/component", ["exports", "@ember/component", "@ember/object", "audio/components/audio-recorder/button/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  /**
   * @module audio/components/audio-recorder
   */
  /**
   * @class AudioButton
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'button',
    classNames: ['audio-button'],
    classNameBindings: ['buttonControl', 'isPlaying', 'isRecording'],
    attributeBindings: ['disabled', 'type'],
    'data-test-audio-recorder-button': true,
    type: 'button',
    disabled: false,
    bubbleEvents: false,
    control: '',
    includePauseIcon: (0, _object.computed)('control', function () {
      let control = (0, _object.get)(this, 'control');
      return control === 'record' || control === 'play';
    }),
    buttonControl: (0, _object.computed)('control', function () {
      let control = (0, _object.get)(this, 'control');
      return control ? `audio-button--${control}` : false;
    }),
    buttonIcon: (0, _object.computed)('control', function () {
      let icon = (0, _object.get)(this, 'control');
      return icon ? `audio-${icon}` : false;
    }),
    click() {
      return this.action();
    }
  });
  _exports.default = _default;
});