define("showbie-live/reducers/channel", ["exports", "@ember/object", "@ember/string", "ember-inflector", "showbie-live/reducers/store-reducer"], function (_exports, _object, _string, _emberInflector, _storeReducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GROUP_POST_UPDATE = _exports.GROUP_POST_DELETE = _exports.GROUP_POST_COUNT = _exports.GROUP_POST_ADD = _exports.GROUP_POST_ACTIVITY_UPDATE = _exports.GROUP_POSTS_CLEAR = void 0;
  /* eslint-disable ember/no-get */

  /**
   * @public
   * a channel post is created
   */
  const GROUP_POST_ADD = 'GROUP-POST-ADD';
  /**
   * @public
   * a post is created
   */
  _exports.GROUP_POST_ADD = GROUP_POST_ADD;
  const GROUP_POST_COUNT = 'GROUP-POST-COUNT';
  /**
   * @public
   * a post has been updated (e.g. a file has been renamed)
   */
  _exports.GROUP_POST_COUNT = GROUP_POST_COUNT;
  const GROUP_POST_UPDATE = 'GROUP-POST-UPDATE';
  /**
   * @public
   * a post has been deleted
   */
  _exports.GROUP_POST_UPDATE = GROUP_POST_UPDATE;
  const GROUP_POST_DELETE = 'GROUP-POST-DELETE';
  /**
   * @public
   * an activity publish is fired
   */
  _exports.GROUP_POST_DELETE = GROUP_POST_DELETE;
  const GROUP_POST_ACTIVITY_UPDATE = 'GROUP-POST-ACTIVITY-UPDATE';
  /**
   * @public
   * a channel's content has been cleared (i.e. Clear All Posts in class
   * discussion)
   */
  _exports.GROUP_POST_ACTIVITY_UPDATE = GROUP_POST_ACTIVITY_UPDATE;
  const GROUP_POSTS_CLEAR = 'GROUP-POSTS-CLEAR';

  /**
   * @public
   * Group subscription reducer
   */
  _exports.GROUP_POSTS_CLEAR = GROUP_POSTS_CLEAR;
  class ChannelReducer extends _storeReducer.default {
    get registeredEvents() {
      return [GROUP_POST_ADD, GROUP_POST_COUNT, GROUP_POST_DELETE, GROUP_POST_UPDATE, GROUP_POST_ACTIVITY_UPDATE, GROUP_POSTS_CLEAR];
    }
    [GROUP_POST_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [GROUP_POST_COUNT](message, store) {
      // can't just push the payload into the store here because doing so
      // will trample any existing meta properties
      let groupId = message.containerId;
      let modelName = message.data.group.type === 'C' ? 'channel' : 'class';
      let group = store.peekRecord(modelName, groupId);
      if (group) {
        (0, _object.set)(group, 'meta.totalPosts', message.data.group.meta.totalPosts);
      }
    }
    [GROUP_POST_DELETE](message, store) {
      let id = (0, _object.get)(message, 'data.channelPost.id');
      if (id) {
        // might also be a folder post?
        let post = store.peekRecord('channelPost', id);
        if (post) {
          store.unloadRecord(post);
        }
      }
    }
    [GROUP_POST_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
    [GROUP_POST_ACTIVITY_UPDATE](message, store) {
      let {
        postType
      } = message.data.activity.meta;
      let postsKey = (0, _emberInflector.pluralize)((0, _string.camelize)(postType));
      message.data[postsKey] = message.data.posts;
      delete message.data.posts;
      store.pushPayload(message.data);
    }
    [GROUP_POSTS_CLEAR](message, store) {
      let id = message.containerId;
      let klass = store.peekRecord('class', id);
      if (klass) klass.clearPosts(false);
    }
  }
  _exports.default = ChannelReducer;
});