define("showbie-master-panel/components/cell-status/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @showIndicator}}
    {{#if @indicateReadOnly}}
      <XIcon
        @class="cell-status__status"
        @symbol="aas-view-only"
        @width="21"
        @height="12"
      />
    {{else}}
      <XIcon
        @class="cell-status__status"
        @symbol="aas-locked"
        @width="14"
        @height="18"
      />
    {{/if}}
  {{/if}}
  {{#if @showDisclosure}}
    <XIcon
      @class="cell-status__disclosure"
      @symbol="disclosure-arrow"
      @width="9"
      @height="15"
    />
  {{/if}}
  */
  {
    "id": "zjYj8ugK",
    "block": "[[[41,[30,1],[[[41,[30,2],[[[1,\"    \"],[8,[39,1],null,[[\"@class\",\"@symbol\",\"@width\",\"@height\"],[\"cell-status__status\",\"aas-view-only\",\"21\",\"12\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@class\",\"@symbol\",\"@width\",\"@height\"],[\"cell-status__status\",\"aas-locked\",\"14\",\"18\"]],null],[1,\"\\n\"]],[]]]],[]],null],[41,[30,3],[[[1,\"  \"],[8,[39,1],null,[[\"@class\",\"@symbol\",\"@width\",\"@height\"],[\"cell-status__disclosure\",\"disclosure-arrow\",\"9\",\"15\"]],null],[1,\"\\n\"]],[]],null]],[\"@showIndicator\",\"@indicateReadOnly\",\"@showDisclosure\"],false,[\"if\",\"x-icon\"]]",
    "moduleName": "showbie-master-panel/components/cell-status/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});