define("ember-svg-jar/inlined/share-icon-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 13v1.5h-3.75a.752.752 0 00-.75.75v13.5c0 .413.337.75.75.75h13.5c.413 0 .75-.337.75-.75v-13.5a.752.752 0 00-.75-.75H21V13h3.75c1.24 0 2.25 1.01 2.25 2.25v13.5c0 1.24-1.01 2.25-2.25 2.25h-13.5C10.01 31 9 29.99 9 28.75v-13.5C9 14.01 10.01 13 11.25 13H15zm2.487-7.782a.749.749 0 011.06.003l4.227 4.258a.75.75 0 01-1.064 1.058l-2.96-2.983v13.734a.75.75 0 01-1.5 0V7.566l-2.964 2.942a.75.75 0 01-1.057-1.063z\" fill=\"#009FE8\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36"
    }
  };
  _exports.default = _default;
});