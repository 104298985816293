define("showbie-master-panel/components/assignment-duedate/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "showbie-master-panel/components/assignment-duedate/template"], function (_exports, _component, _object, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  /**
   * Renders an assignment's due date, Showbie-style.
   * @usage
   * ```
   * {{assignment-duedate date=someDate}}
   * {{assignment-duedate date=assignment.duedate isArchived=true}}
   * {{assignment-duedate date=assignment.duedate capitalize=true}}
   * ```
   * @public
   */
  var _default = _component.default.extend({
    delorean: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    layout: _template.default,
    tagName: 'time',
    attributeBindings: ['datetime'],
    /**
     * @property date
     * @type {UnixTimestamp|Moment|Date}
     * @public
     */
    date: null,
    /**
     * Is the assignment archived?
     * @property isArchived
     * @type {Boolean}
     * @public
     */
    isArchived: null,
    /**
     * Opt-in to forcing capitalized output.
     * For example, "Due" and "No due date" instead of "due" and "no due date"
     * @type {Boolean}
     * @public
     */
    capitalize: false,
    /*
     * Localized due date output.
     */
    output: (0, _object.computed)('date', 'isArchived', 'capitalize', 'intl.primaryLocale', function () {
      let duedate = (0, _object.get)(this, 'date');
      let isArchived = (0, _object.get)(this, 'isArchived');
      let capitalize = (0, _object.get)(this, 'capitalize');
      let intl = (0, _object.get)(this, 'intl');
      let formattedDate = (0, _object.get)(this, 'delorean').duedate((0, _object.get)(this, 'date'));
      let key;
      if (!duedate) {
        key = capitalize ? 'No due date' : 'no due date';
      } else if (isArchived) {
        key = capitalize ? 'Archived {a1}' : 'archived {a1}';
      } else {
        key = capitalize ? 'Due {a1}' : 'due {a1}';
      }
      return intl.t(key, {
        a1: formattedDate
      });
    }),
    /*
     * Machine-readable date for `datetime` attribute.
     */
    datetime: (0, _object.computed)('date', function () {
      return (0, _object.get)(this, 'delorean').iso((0, _object.get)(this, 'date'));
    })
  });
  _exports.default = _default;
});