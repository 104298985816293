define("ember-svg-jar/inlined/showbie-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M157.61 7.39l-.17-.51c-1.22-4.2-4.33-6.76-8.66-6.87H37.77a8.68 8.68 0 00-8.12 4.9L0 105.67v36.65a18.33 18.33 0 0018.28 18.4l74 .28h75.7a18.36 18.36 0 0018.34-18.34v-37L157.63 7.4zm-20.3 98.28a15.56 15.56 0 00-14.97 11.4 30.98 30.98 0 01-28.95 19.87 31.04 31.04 0 01-28.96-19.88 15.55 15.55 0 00-14.96-11.4H11.76L38.92 10.1h108.63l27.15 95.6-37.4-.03z\" fill=\"#29B1F0\"/><path d=\"M114.67 29.66a70.61 70.61 0 00-32.15-4.1c-2.02.15-3.76.48-5.3.74-1.3.19-2.58.46-3.84.8l-3.15.77a7.05 7.05 0 00-4.05 3.01c-1.6 2.89.4 6.15 4.5 7.29 1.92.54 3.96.54 5.9 0l2.19-.57c.86-.27 1.75-.47 2.65-.6a50.2 50.2 0 0126.44 1.92c8.6 3.07 16.87 9.62 19.07 18.17 2.43 8.4-2.11 18.23-12 24.2a45.37 45.37 0 01-17.22 5.8c-6.15.82-12.4.48-18.42-.98-11.67-2.74-19.66-9.5-22.61-16a18.73 18.73 0 01-.26-16.25c.73-1.51 1.61-2.94 2.64-4.25l1.1-1.45h-.07c.13-.12.25-.25.35-.4.87-1.54-.21-3.3-2.43-3.92a5.28 5.28 0 00-5.08 1.18h-.1l-1.44 1.76a29.45 29.45 0 00-3.47 5.2 25 25 0 00-2.49 8.84c-.4 3.98.29 8 1.97 11.63 3.4 8.27 13.28 17.22 28.27 21.07a65.66 65.66 0 0024.1 1.73 60.8 60.8 0 0023.14-7.29c13.56-7.74 20.27-20.88 17.36-32.54-2.47-11.82-13.62-21.25-25.6-25.76z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "187",
      "height": "161",
      "viewBox": "0 0 187 161",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});