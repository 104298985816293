define("ember-svg-jar/inlined/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M175.233 0C183.936 0 191 7.05 191 15.743v126.514c0 8.693-7.064 15.743-15.767 15.743H15.767C7.064 158 0 150.95 0 142.257V15.743C0 7.05 7.064 0 15.767 0zM61 48.023l-54 .22v94.014c0 4.737 3.788 8.603 8.508 8.74l.259.003H61V48.023zm62-.253l-55 .224V151h55V47.77zm61-.249l-54 .221V151h45.233c4.754 0 8.626-3.78 8.763-8.486l.004-.257V47.52zM61 7H15.767C10.927 7 7 10.92 7 15.743v25.501l54-.22V7zm62 0H68v33.995l55-.224V7zm52.233 0H130v33.742l54-.22V15.743c0-4.737-3.788-8.603-8.508-8.74L175.233 7z\"/>",
    "attrs": {
      "width": "191",
      "height": "158",
      "viewBox": "0 0 191 158",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});