define("showbie-paywall/components/sbe-paywall/component", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/service", "showbie-paywall/components/sbe-paywall/template", "ember-window-mock"], function (_exports, _component, _computed, _object, _service, _template, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SbePaywallComponent = (_dec = (0, _computed.readOnly)('paywall.isVisible'), _dec2 = (0, _computed.readOnly)('paywall.showVideoChatPaywall'), _dec3 = (0, _computed.readOnly)('session.apiHeaders.Authorization'), _dec4 = (0, _object.computed)('router.currentURL'), _dec5 = (0, _object.computed)('media.isSmall'), _dec6 = (0, _object.computed)('sales.namespace'), (_class = class SbePaywallComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "media", _descriptor2, this);
      _initializerDefineProperty(this, "paywall", _descriptor3, this);
      _initializerDefineProperty(this, "sales", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "session", _descriptor6, this);
      _defineProperty(this, "layout", _template.default);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "keyboardPriority", 0);
      _initializerDefineProperty(this, "paywallIsVisible", _descriptor7, this);
      _initializerDefineProperty(this, "showVideoChatPaywall", _descriptor8, this);
      _initializerDefineProperty(this, "authorizationHeader", _descriptor9, this);
    }
    get redirectUri() {
      return _emberWindowMock.default.location.href;
    }
    get videoChatHeader() {
      return this.intl.t(this.media.isSmall ? 'Video Chat' : 'Video Chat on Showbie');
    }
    get stripeSessionUrl() {
      return this.sales.buildUrl('licences/stripe-session');
    }
    close() {
      let paywall = this.paywall;
      paywall.hide();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "paywall", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sales", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "paywallIsVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showVideoChatPaywall", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "authorizationHeader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "redirectUri", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "redirectUri"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "videoChatHeader", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "videoChatHeader"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stripeSessionUrl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "stripeSessionUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = SbePaywallComponent;
});