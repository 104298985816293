define("ember-svg-jar/inlined/cell-icon-lock-teachers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 20H1a1 1 0 01-1-1v-9a1 1 0 011-1h1V6a6 6 0 0112 0v3h1a1 1 0 011 1v9a1 1 0 01-1 1zM12 6a4 4 0 00-8 0v3h8V6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20"
    }
  };
  _exports.default = _default;
});