define("showbie-master-panel/components/model-filter/component", ["exports", "@ember/template-factory", "@ember/component", "@ember/object", "@ember/object/computed", "showbie-utils/utils/collection-sorting"], function (_exports, _templateFactory, _component, _object, _computed, _collectionSorting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-computed-property-dependencies */
  /**
   * This container component takes Assignment and Topic lists from
   * the route model and filters them appropriately.
   *
   * @class ModelFilter
   * @extends Ember.Component
   * @todo Rename this component to assignments-parser so it's less generic
   */
  var _default = _component.default.extend({
    layout: (0, _templateFactory.createTemplateFactory)(
    /*
      
        {{yield
          (hash
            topics=this.sortedTopics
            assigned=this.assignedAssignments
            collected=this.collectedAssignments
            archived=this.archivedAssignments
            count=this.assignmentCount
            countWithArchived=this.assignmentCountWithArchived
            hasNoTopics=this.hasNoTopics
            hasNoAssignments=this.hasNoAssignments
            hasNoAssignmentsWithArchived=this.hasNoAssignmentsWithArchived
            isEmpty=this.isEmpty
            isEmptyWithArchived=this.isEmptyWithArchived
          )
        }}
      
    */
    {
      "id": "2bmJEVb8",
      "block": "[[[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"topics\",\"assigned\",\"collected\",\"archived\",\"count\",\"countWithArchived\",\"hasNoTopics\",\"hasNoAssignments\",\"hasNoAssignmentsWithArchived\",\"isEmpty\",\"isEmptyWithArchived\"],[[30,0,[\"sortedTopics\"]],[30,0,[\"assignedAssignments\"]],[30,0,[\"collectedAssignments\"]],[30,0,[\"archivedAssignments\"]],[30,0,[\"assignmentCount\"]],[30,0,[\"assignmentCountWithArchived\"]],[30,0,[\"hasNoTopics\"]],[30,0,[\"hasNoAssignments\"]],[30,0,[\"hasNoAssignmentsWithArchived\"]],[30,0,[\"isEmpty\"]],[30,0,[\"isEmptyWithArchived\"]]]]]]],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
      "moduleName": "/Users/keithkoshman/Dev/showbie/web/packages/showbie/showbie-master-panel/components/model-filter/component.js",
      "isStrictMode": false
    }),
    tagName: '',
    assignments: null,
    topics: null,
    isAllAssignments: false,
    hasNoTopics: (0, _computed.empty)('sortedTopics'),
    hasNoAssigned: (0, _computed.empty)('assignedAssignments'),
    hasNoCollected: (0, _computed.empty)('collectedAssignments'),
    hasNoArchived: (0, _computed.empty)('archivedAssignments'),
    hasNoAssignments: (0, _computed.and)('hasNoAssigned', 'hasNoCollected'),
    isEmpty: (0, _computed.and)('hasNoTopics', 'hasNoAssigned', 'hasNoCollected'),
    activeAssignments: (0, _computed.filterBy)('assignments', 'isActive'),
    hasNoAssignmentsWithArchived: (0, _computed.and)('hasNoAssigned', 'hasNoCollected', 'hasNoArchived'),
    isEmptyWithArchived: (0, _computed.and)('hasNoTopics', 'hasNoAssigned', 'hasNoCollected', 'hasNoArchived'),
    assignedAssignments: (0, _object.computed)('assignments.@each.{isActive,isArchived,isCollected,isNew,dueDate,sortDateStamp,topic}', 'sortOrder', function () {
      let activeAssignments = (0, _object.get)(this, 'activeAssignments').rejectBy('isNew');
      return activeAssignments.rejectBy('isCollected').sort(_collectionSorting.assignedAssignmentSort);
    }),
    collectedAssignments: (0, _object.computed)('assignments.@each.{isActive,isArchived,isCollected,isNew,dueDate,sortDateStamp,topic}', 'sortOrder', function () {
      let activeAssignments = (0, _object.get)(this, 'activeAssignments').rejectBy('isNew');
      return activeAssignments.filterBy('isCollected').sort(_collectionSorting.collectedAssignmentSort);
    }),
    archivedAssignments: (0, _object.computed)('assignments.@each.{isArchived,sortDateStamp}', 'isAllAssignments', 'sortOrder', function () {
      let assignments = (0, _object.get)(this, 'assignments');
      let isAllAssignments = (0, _object.get)(this, 'isAllAssignments');
      return assignments.filterBy('isArchived')
      // don't show unarchived assignments from archived classes in "all assignment" view
      .reject(assignment => isAllAssignments && ((0, _object.get)(assignment, 'class.isArchived') || (0, _object.get)(assignment, 'topic.isArchived'))).sort(_collectionSorting.archivedAssignmentSort);
    }),
    assignmentCount: (0, _object.computed)('assignedAssignments.[]', 'collectedAssignments.[]', function () {
      let assigned = (0, _object.get)(this, 'assignedAssignments').length;
      let collected = (0, _object.get)(this, 'collectedAssignments').length;
      return assigned + collected;
    }),
    assignmentCountWithArchived: (0, _object.computed)('assignmentCount', 'archivedAssignments.[]', function () {
      let active = (0, _object.get)(this, 'assignmentCount');
      let archived = (0, _object.get)(this, 'archivedAssignments').length;
      return active + archived;
    }),
    sortedTopics: (0, _object.computed)('topics.@each.sortDateStamp', function () {
      let topics = (0, _object.get)(this, 'topics') || [];
      return topics.filterBy('isActive').sort(_collectionSorting.topicSort);
    })
  });
  _exports.default = _default;
});