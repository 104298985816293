define("showbie-live/reducers/folder", ["exports", "@ember/object", "showbie-live/reducers/store-reducer"], function (_exports, _object, _storeReducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FOLDER_POST_UPDATE = _exports.FOLDER_POST_DELETE = _exports.FOLDER_POST_ADD = _exports.FOLDER_POST_ACTIVITY_UPDATE = void 0;
  /* eslint-disable ember/no-get */

  /**
   * @public
   * @scope {Showbie.Model.Folder}
   * Triggered when an assignment update or archive/unarchive is performed or when an assignment schedule is processed through our scheduler and changes the assignments student access level
   */
  const FOLDER_POST_ADD = 'FOLDER-POST-ADD';

  /**
   * @public
   * @scope {Showbie.Model.Folder}
   * Triggered when updating a post such as renaming a file
   */
  _exports.FOLDER_POST_ADD = FOLDER_POST_ADD;
  const FOLDER_POST_UPDATE = 'FOLDER-POST-UPDATE';

  /**
   * @public
   * @scope {Showbie.Model.Folder}
   * Triggered when an assignment is deleted
   */
  _exports.FOLDER_POST_UPDATE = FOLDER_POST_UPDATE;
  const FOLDER_POST_DELETE = 'FOLDER-POST-DELETE';

  /**
   * @public
   * @scope {Showbie.Model.Folder}
   * Triggered when an assignment is deleted
   */
  _exports.FOLDER_POST_DELETE = FOLDER_POST_DELETE;
  const FOLDER_POST_ACTIVITY_UPDATE = 'FOLDER-POST-ACTIVITY-UPDATE';

  /**
   * @public
   * @scope {Showbie.Model.Folder}
   * Assignment subscription reducer
   */
  _exports.FOLDER_POST_ACTIVITY_UPDATE = FOLDER_POST_ACTIVITY_UPDATE;
  class FolderReducer extends _storeReducer.default {
    get registeredEvents() {
      return [FOLDER_POST_ADD, FOLDER_POST_UPDATE, FOLDER_POST_DELETE, FOLDER_POST_ACTIVITY_UPDATE];
    }
    [FOLDER_POST_ADD](message, store) {
      store.pushPayload(message.data);
    }
    [FOLDER_POST_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
    [FOLDER_POST_DELETE](message, store) {
      let id = (0, _object.get)(message, 'objectId');
      if (id) {
        let post = store.peekRecord('folderPost', id);
        if (post) {
          /* NOTE: this deletes the local store record, but keeps it in the store
             with an `isDeleted` of true. `unloadRecord()` leads to issues with
             the post list trying to access null properties, so for now it's
             commented out. */
          post.deleteRecord();
          // post.unloadRecord();
        }
      }
    }

    [FOLDER_POST_ACTIVITY_UPDATE](message, store) {
      store.pushPayload(message.data);
    }
  }
  _exports.default = FolderReducer;
});