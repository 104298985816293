define("showbie-live/firehose/service", ["exports", "@ember/debug", "@ember/object", "@ember/array", "@ember/runloop", "@ember/service", "showbie/utils/auto-retry-socket", "showbie-live/reducers/app", "showbie/config/environment", "ember"], function (_exports, _debug, _object, _array, _runloop, _service, _autoRetrySocket, _app, _environment, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get */

  const {
    CompositeDisposable,
    Disposable,
    Observable,
    ReplaySubject
  } = Rx;
  let disposable;
  var _default = _service.default.extend({
    api: (0, _service.inject)(),
    store: (0, _service.inject)(),
    freshness: (0, _service.inject)(),
    features: (0, _service.inject)(),
    redux: (0, _service.inject)(),
    scheduleManager: (0, _service.inject)(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    connectionSubject: new ReplaySubject(1),
    init() {
      this._super(...arguments);
      this.connections = this.connectionSubject.switchMap(url => {
        return new _autoRetrySocket.default(url, (0, _runloop.bind)(this, this.featureFilter));
      }).shareReplay(1);
    },
    setup(registrationUrl, token) {
      let url = this.features.isEnabled('region-resolution') && !_ember.default.testing && !_environment.default['ember-cli-mirage'].enabled ? registrationUrl : `${_environment.default.apiConfig.services.live.namespace}/registrations`;
      disposable = this.connect(url, token);
    },
    teardown() {
      if (disposable) {
        disposable.dispose();
      }
      disposable = null;
    },
    /**
     * @private
     * Request the socket URL from the Showbie service
     * @param {string} url
     * @param {string} jwtToken
     * @return {string}
     */
    _register(url, jwtToken) {
      return (0, _object.get)(this, 'api').post(url, null, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }).then(_ref => {
        let {
          showbieLive
        } = _ref;
        return showbieLive.url;
      });
    },
    featureFilter( /* msg */
    ) {
      return true;
    },
    /**
     * @public
     * Kick off the connection to the live events service
     * @param {string} url - The URL used to connect to the socket
     * @param {string} jwtToken - The authentication token for the request
     * @return {Rx.Disposable}
     */
    connect(url, jwtToken) {
      let freshnessService = this.freshness;
      let scheduleManagerService = this.scheduleManager;
      let reducer = new _app.default(this.store, freshnessService, this.redux, scheduleManagerService);
      let handler = data => reducer.handle(data, this.store);
      return Observable.fromPromise(this._register(url, jwtToken)).switchMap(url => {
        let observable = this.connections.switch();
        this.connectionSubject.onNext(url);
        return observable;
      }).subscribe(msg => {
        handler(msg);
      }, error => {
        (0, _debug.debug)(`Live Events firehose connection failed, ${error}`);
      });
    },
    /**
     * @public
     * Create a new subscription to a container
     * @param {string} containerId - the ID of the group to subscribe to
     * @param {Showbie.Reducer} reducer
     * @return {Rx.Disposable}
     */
    subscribeToContainer(containerId, reducer) {
      let {
        registeredEvents
      } = reducer;
      let buildRegistrationMessages = function () {
        let enabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        return registeredEvents.map(event => ({
          containerId,
          event,
          enabled
        })).map(JSON.stringify);
      };
      let send = (message, socket) => socket.onNext(message);
      let onConnect = socket => buildRegistrationMessages().forEach(msg => send(msg, socket));
      let onComplete = socket => buildRegistrationMessages(false).forEach(msg => send(msg, socket));
      let observable = Observable.create(o => {
        let _socketSubject;
        let connectionSubscription = this.connections.do(socketSubject => {
          // send register messages when there is a socket available
          (0, _debug.debug)(`Registering ${JSON.stringify(registeredEvents)} for container ${containerId}`);
          onConnect(socketSubject);
          // stash the socket for unsubscription
          _socketSubject = socketSubject;
        }).switch().filter(msg => msg.containerId == containerId).subscribe(o);
        let onCompleteDisposable = Disposable.create(() => {
          // send off unregister messages
          if (_socketSubject) {
            onComplete(_socketSubject);
          }
          (0, _debug.debug)(`Unregistering ${JSON.stringify(registeredEvents)} for container ${containerId}`);
        });
        return new CompositeDisposable(connectionSubscription, onCompleteDisposable);
      });
      return observable.subscribe(msg => reducer.handle(msg));
    },
    /**
     * Get a stream of events filtered by event type
     * @param  {String} eventName
     * @return {Rx.Observable}
     * @public
     */
    eventStream(eventName) {
      return this.stream().filter(msg => (0, _array.isArray)(eventName) ? eventName.includes(msg.event) : msg.event === eventName);
    },
    /**
     * Get the full firehose of events.
     * @return {Rx.Observable}
     * @public
     */
    stream() {
      return this.connections.switch();
    },
    /**
     * Get a stream of events for the given object id
     * @param  {(String|Number)} objectId
     * @return {Rx.Observable}
     * @public
     */
    streamFor(objectId) {
      return this.stream().filter(msg => msg.objectId == objectId);
    }
  });
  _exports.default = _default;
});