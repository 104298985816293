define("audio/components/audio-player/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.localUrl}}
    <XAudio @url={{this.localUrl}} @mime={{@model.contentType}} @pin={{@pin}} />
  {{else}}
    {{#if this.errorAudioFormat}}
      <div class="post__audio__comment post__audio--unsupported">
        {{t "Unsupported voice note format."}}
        <a
          href="https://support.showbie.com/showbie-faqs-and-troubleshooting/why-cant-i-record-voice-notes-on-the-web-app"
          target="_blank"
          rel="noopener noreferrer"
        >{{t "Learn more…"}}</a>
      </div>
    {{else}}
      <a {{action "play"}} class="post__audio__comment" data-test-play-button>
        <div class="post__audio__comment--inner">
          {{#if this.error}}
            {{this.error}}
          {{else}}
            <span class="voice-note-control--icon"></span>
            <span class="voice-note-control--filename">{{@name}}</span>
          {{/if}}
        </div>
      </a>
    {{/if}}
  {{/if}}
  */
  {
    "id": "wW9q4e9l",
    "block": "[[[41,[30,0,[\"localUrl\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@url\",\"@mime\",\"@pin\"],[[30,0,[\"localUrl\"]],[30,1,[\"contentType\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"errorAudioFormat\"]],[[[1,\"    \"],[10,0],[14,0,\"post__audio__comment post__audio--unsupported\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"Unsupported voice note format.\"],null]],[1,\"\\n      \"],[10,3],[14,6,\"https://support.showbie.com/showbie-faqs-and-troubleshooting/why-cant-i-record-voice-notes-on-the-web-app\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[28,[35,2],[\"Learn more…\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,0,\"post__audio__comment\"],[24,\"data-test-play-button\",\"\"],[4,[38,3],[[30,0],\"play\"],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"post__audio__comment--inner\"],[12],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"          \"],[1,[30,0,[\"error\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"voice-note-control--icon\"],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"voice-note-control--filename\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@model\",\"@pin\",\"@name\"],false,[\"if\",\"x-audio\",\"t\",\"action\"]]",
    "moduleName": "audio/components/audio-player/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});