define("ember-svg-jar/inlined/clipboard-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.196 12.152h1.494V10.21c0-.398.104-.705.312-.918.208-.214.523-.321.945-.321h3.56v4.104c0 .498.127.872.382 1.12.255.25.629.374 1.12.374h3.771v6.988c0 .404-.104.713-.312.927-.208.214-.523.32-.945.32h-1.6V24.3h1.706c.879 0 1.54-.224 1.982-.673.442-.448.663-1.114.663-1.999v-6.662c0-.527-.061-.976-.184-1.345s-.346-.718-.668-1.046L22.256 8.33a2.754 2.754 0 00-1.015-.663 3.656 3.656 0 00-1.244-.198h-3.164c-.867 0-1.523.225-1.969.677-.445.45-.668 1.116-.668 1.995v2.012zm7.603.721V9.63l3.595 3.656h-3.182c-.275 0-.413-.138-.413-.413zm-11.68 12.964c0 .885.222 1.551.667 2 .445.448 1.105.672 1.978.672h7.796c.873 0 1.532-.224 1.977-.673.445-.448.668-1.114.668-2v-6.433c0-.55-.051-.983-.154-1.296-.102-.314-.323-.643-.663-.989l-4.535-4.623c-.329-.334-.652-.554-.972-.66-.319-.105-.722-.157-1.208-.157h-2.91c-.872 0-1.532.225-1.977.676-.445.452-.668 1.117-.668 1.996v11.487zm1.502-.07V14.41c0-.392.104-.696.312-.91.208-.213.52-.32.936-.32h2.628v4.667c0 .568.14.99.422 1.265.281.276.7.413 1.257.413h4.535v6.24c0 .405-.106.714-.316.928-.211.214-.525.32-.94.32H12.86c-.416 0-.726-.106-.931-.32-.205-.214-.308-.523-.308-.927zm5.713-7.585c-.17 0-.293-.038-.37-.115-.075-.076-.113-.199-.113-.369v-4.21l4.614 4.694h-4.131z\" fill=\"#009FE8\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});