define("showbie-paywall/paywall-slides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INTRO_SLIDES = _exports.FEATURE_SLIDES = void 0;
  const INTRO_SLIDES = {
    upgrade: {
      title: 'Take Your Feedback Further',
      text: 'Showbie Pro is your complete hybrid learning platform packed with additional features and upgrades to help you save time, collaborate more, and give richer feedback to all your students. When you go Pro, all of your students get upgraded too!',
      image: '/showbie-paywall/images/paywall-upgrade.svg'
    }
  };
  _exports.INTRO_SLIDES = INTRO_SLIDES;
  const FEATURE_SLIDES = [{
    title: 'Infinite Assignments',
    slug: 'Assignment_Count',
    text: 'With unlimited assignment creation and archiving you can keep all your classroom content in Showbie for as long as you need it, so you’re always ready to engage your students.',
    image: '/showbie-paywall/images/paywall-assignment-limit.svg'
  }, {
    title: 'Keep Your Classroom Organized',
    slug: 'Class_Folders',
    text: 'Use Folders to customize your class in a way that works best for you and your students. File assignments into convenient color-codable sets, and make it easy to keep your class tidy and your students on track.',
    image: '/showbie-paywall/images/paywall-class-folders.svg'
  }, {
    title: 'Invite Your Co-Teachers',
    slug: 'Co_Teaching',
    text: 'Make sure your class never misses a beat with access to Co-Teaching. Share your class with a co-teacher to lighten your load, whether it’s just for the day, term, or the whole year.',
    image: '/showbie-paywall/images/paywall-co-teaching.svg'
  }, {
    title: 'Empowered Class Discussions',
    slug: 'Class_Discussion',
    text: 'With Class Discussions, you and your students can chat, collaborate, and share class resources in real-time. You can stay in control by pausing or resuming the discussion with a single tap.',
    image: '/showbie-paywall/images/paywall-class-discussion.svg'
  }, {
    title: 'Track Student Progress',
    slug: 'Portfolio',
    text: 'With student Portfolios in Showbie Pro, you can easily collect examples of your students’ best work, show evidence of their progress over time, and share learning success with parents.',
    image: '/showbie-paywall/images/paywall-portfolio.svg'
  }, {
    title: 'Collaborate With Groups',
    slug: 'Groups',
    text: 'Create Groups to enable collaborative student learning or group work. With Groups you can also share resources with colleagues in your school, coordinate extracurricular activities, or send announcements to parents.',
    image: '/showbie-paywall/images/paywall-groups.svg'
  }, {
    title: 'Save Time Grading',
    slug: 'Gradebook',
    text: 'Showbie’s built-in Gradebook is the fast and flexible way to grade all your student work, review the grades you’ve assigned, and easily export grades to other school systems — all within the app.',
    image: '/showbie-paywall/images/paywall-grade.svg'
  }, {
    title: 'Engage Your Learning Community',
    slug: 'Parent_Access',
    text: 'Parent Access accounts enable parents to view classes, assignments, and portfolio highlights so they can stay informed of student progress.',
    image: '/showbie-paywall/images/paywall-parent-access.svg'
  }, {
    title: 'Longer Voice Notes',
    slug: 'Voicenote_Limit',
    text: 'Take your student feedback even further with {a1} minute voice notes. With Pro, you can engage your students more deeply, and truly personalize support and feedback with each student.',
    translationKey: '30',
    image: '/showbie-paywall/images/paywall-voicelength.svg'
  }, {
    title: 'Larger File Uploads',
    slug: 'Filesize_Limit',
    text: 'Supercharge your lessons and assignments with the flexibility to upload files up to {a1}MB straight into Showbie, and boost student engagement with large video files or creative content.',
    translationKey: '250',
    image: '/showbie-paywall/images/paywall-filesize.svg'
  }, {
    title: 'Access Past Class Content',
    slug: 'Archived_Visibility',
    text: 'Take a peek into archived classes to access assignments and resources that you can adapt into your current lessons. Copy folder structures, export grades, and add items to student portfolios without the need to fully restore the class.',
    image: '/showbie-paywall/images/paywall-cabinet.svg'
  }];
  _exports.FEATURE_SLIDES = FEATURE_SLIDES;
});