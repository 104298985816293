define("torii/routing/application-route-mixin", ["exports", "@ember/object/mixin", "torii/configuration", "@ember/application"], function (_exports, _mixin, _configuration, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    beforeModel(transition) {
      var route = this;
      var superBefore = this._super.apply(this, arguments);
      if (superBefore && superBefore.then) {
        return superBefore.then(function () {
          return route.checkLogin(transition);
        });
      } else {
        return route.checkLogin(transition);
      }
    },
    checkLogin() {
      let configuration = (0, _configuration.getConfiguration)();
      let sessionService = (0, _application.getOwner)(this).lookup(`service:${configuration.sessionServiceName}`);
      return sessionService.fetch().catch(function () {
        // no-op, cause no session is ok
      });
    }
  });
  _exports.default = _default;
});