define("ember-svg-jar/inlined/folder-move-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.581 9c1.213 0 2.186.829 2.423 2.064l.252.899 7.644.009c1.158 0 2.1.938 2.1 2.093v10.728C29 25.949 27.944 27 26.784 27H9.215C8.055 27 7 25.949 7 24.793V23h1.5v1.793c0 .325.387.707.715.707h17.57c.327 0 .715-.382.715-.707V14.065a.597.597 0 00-.6-.593l-7.563-.001c-.815 0-1.42-.496-1.58-1.296l.72-.22-.723.203-.209-.751c-.093-.47-.383-.907-.964-.907h-7.11a.969.969 0 00-.97.964V16H7v-4.536A2.47 2.47 0 019.47 9zm-4.857 6.226a.75.75 0 011.061-.012l3.416 3.339a.753.753 0 01-.03 1.101l-3.609 3.16a.748.748 0 01-1.058-.07.75.75 0 01.07-1.058l2.21-1.936H3.777a.75.75 0 010-1.5h9.97l-2.01-1.964a.749.749 0 01-.013-1.06z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});