define("ember-svg-jar/inlined/pencil-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M16.36.92l1.19 1.161c.29.28.45.65.45 1.05 0 .401-.16.771-.45 1.051l-.774.752-3.325-3.249.788-.765a1.549 1.549 0 012.12 0zm-3.985 1.81l-9.9 9.607 3.404 3.171L15.7 5.98l-3.324-3.25zM1.59 13.561L.528 16.85a.492.492 0 00.469.651c.05 0 .1 0 .15-.02l3.498-1.072-3.055-2.846z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});