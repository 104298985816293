define("ember-svg-jar/inlined/socrative-no-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.466.527A1.079 1.079 0 0018.538 0h-3.425c-.38.004-.73.208-.921.538l-1.707 2.948-.006.011c-.186.33-.184.735.006 1.064l1.707 2.949.006.01c.194.327.546.527.927.527h3.425a1.08 1.08 0 00.922-.537l1.706-2.949.007-.01a1.076 1.076 0 00-.006-1.065L19.471.538l-.006-.01zM9.312 5.324c.385 0 .741.205.934.538l1.706 2.948c.193.333.193.743 0 1.076l-1.706 2.948a1.079 1.079 0 01-.934.538H5.9c-.385 0-.74-.205-.933-.538L3.259 9.886a1.073 1.073 0 010-1.076l1.707-2.948c.192-.333.548-.538.933-.538h3.413zm10.154 5.832a1.079 1.079 0 00-.928-.528h-3.425c-.38.005-.73.209-.921.538l-1.707 2.948-.006.011c-.186.331-.184.736.006 1.065l1.707 2.948.006.01c.194.327.546.528.927.528h3.425c.38-.005.731-.209.922-.538l1.706-2.948.007-.011a1.076 1.076 0 00-.006-1.065l-1.707-2.948-.006-.01zM10.24 16.48a1.079 1.079 0 00-.928-.527H5.887c-.38.004-.73.208-.921.537l-1.707 2.95-.006.01c-.187.331-.184.736.006 1.065l1.707 2.948.006.01c.194.327.546.528.927.528h3.425c.38-.004.731-.208.922-.538l1.706-2.948.007-.011c.186-.33.184-.735-.007-1.064l-1.706-2.949-.006-.01z\" fill=\"#FF753F\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});