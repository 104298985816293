define("ember-svg-jar/inlined/post-right-down-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.78 12.967a.751.751 0 00-1.06 0l-2.927 2.928V6.75a.75.75 0 00-.75-.75H3.75a.75.75 0 000 1.5h11.543v8.481l-3.013-3.014a.751.751 0 00-1.06 1.061l4.25 4.252a.75.75 0 001.06 0l4.25-4.252a.75.75 0 000-1.06\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});