define("ember-svg-jar/inlined/lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.352 15.773h5.295v-2.67c0-1.473-1.188-2.671-2.648-2.671-1.46 0-2.647 1.198-2.647 2.67v2.67zm6.045 1.5h-6.795a.75.75 0 01-.75-.75v-3.42c0-2.3 1.86-4.171 4.147-4.171 2.288 0 4.148 1.87 4.148 4.17v3.42a.75.75 0 01-.75.75zm-10.19 0a.712.712 0 00-.708.716v8.796c0 .394.317.716.707.716h13.587c.39 0 .706-.322.706-.716v-8.796a.711.711 0 00-.706-.716h-.485a.75.75 0 01-.75-.75v-3.42c0-3.09-2.493-5.602-5.559-5.602-3.065 0-5.558 2.513-5.558 5.602v3.42a.75.75 0 01-.75.75h-.485zM24.792 29H11.206A2.215 2.215 0 019 26.785v-8.796c0-1.132.85-2.07 1.942-2.2v-2.686C10.941 9.187 14.107 6 18 6s7.06 3.186 7.06 7.102v2.686a2.215 2.215 0 011.94 2.2v8.796A2.214 2.214 0 0124.793 29z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});