define("ember-svg-jar/inlined/icon-avatar-deactivated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M33.818 35.116a14.457 14.457 0 01-9.326 3.392c-8.017 0-14.515-6.5-14.515-14.516 0-3.55 1.274-6.804 3.391-9.326l20.45 20.45zm2.686-2.971L16.34 11.98a14.448 14.448 0 018.152-2.503c8.017 0 14.516 6.498 14.516 14.515 0 3.022-.924 5.829-2.504 8.153zm6.504-8.153c0 10.226-8.29 18.516-18.516 18.516-10.226 0-18.515-8.29-18.515-18.516 0-10.226 8.29-18.515 18.515-18.515 10.226 0 18.516 8.29 18.516 18.515z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "49",
      "height": "48",
      "viewBox": "0 0 49 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});