define("memory-scroll/components/memory-scroll", ["exports", "memory-scroll/components/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    targetElement() {
      return this.element;
    }
  });
  _exports.default = _default;
});