define("ember-svg-jar/inlined/alert-warning-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M10.178 2.052c.81-1.403 2.834-1.403 3.644 0l9.893 17.135c.81 1.402-.203 3.156-1.822 3.156H2.107c-1.62 0-2.632-1.753-1.822-3.156l9.893-17.135z\" fill=\"#BB7D00\"/><path d=\"M12.518 15.218h-1.47l-.66-9.42h2.774l-.645 9.42zm-2.086 2.76c0-.4.146-.725.435-.975.3-.26.655-.39 1.065-.39.41 0 .736.125.976.375.24.24.36.53.36.87 0 .42-.146.75-.436.99-.28.24-.655.36-1.125.36a1.3 1.3 0 01-.885-.345c-.26-.24-.39-.535-.39-.885z\" fill=\"#fff\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});