define("@glimmer/component/index", ["exports", "@ember/component", "@glimmer/component/-private/ember-component-manager", "@glimmer/component/-private/component", "@ember/application"], function (_exports, _component, _emberComponentManager, _component2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Hax because Ember does not have types for `setComponentManager`

  class GlimmerComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      if (false /* DEBUG */ && !(owner !== null && typeof owner === 'object')) {
        throw new Error(`You must pass both the owner and args to super() in your component: ${this.constructor.name}. You can pass them directly, or use ...arguments to pass all arguments through.`);
      }
      (0, _application.setOwner)(this, owner);
    }
  }
  _exports.default = GlimmerComponent;
  (0, _component.setComponentManager)(owner => {
    return new _emberComponentManager.default(owner);
  }, GlimmerComponent);
});