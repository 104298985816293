/* eslint-env node, browser, es6:false */
(function () {
  function vendorModule() {
    /* globals define */
    'use strict';

    function intercom() {
      if (typeof self.Intercom === 'function') {
        return self.Intercom.apply(null, arguments);
      } else {
        let i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        self.Intercom = i;
      }
    }

    return {
      default: intercom,
      __esModule: true,
    };
  }

  define('intercom', [], vendorModule);
})();
