define("ember-svg-jar/inlined/star-icon-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.998 2.666l.101.008c.23.037.418.2.491.425l2.066 5.925 6.701.017.098.006c.255.034.46.198.526.427l.017.091c.02.212-.095.419-.3.551l-5.408 4.009 2.075 5.991.03.1a.674.674 0 01-.097.501l-.068.081a.664.664 0 01-.473.202l-.106-.01a.603.603 0 01-.286-.132l-5.367-3.701-5.35 3.693-.073.05c-.1.062-.215.1-.335.1l-.103-.008a.643.643 0 01-.422-.26l-.055-.086a.631.631 0 01-.037-.496l2.084-6.018-5.409-4.016-.084-.061a.55.55 0 01-.191-.588l.036-.093c.1-.206.318-.333.58-.333l6.701-.017 2.058-5.916a.634.634 0 01.6-.442z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});