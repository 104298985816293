define("audio/helpers/duration", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.duration = duration;
  /**
   * Format a millisecond value as a timer readout → 0:00:00
   *
   * In some cases — when counting down for example — it makes more
   * sense to round up when calculating the seconds. Setting `roundUp`
   * to `true` will enable this. Milliseconds are always rounded down
   * when calculating minutes and hours, however.
   *
   * @export
   * @param {number} [milliseconds=0]
   * @param {boolean} [roundUp=false]
   * @returns {string} Formatted time readout
   */
  function duration(_ref) {
    let [milliseconds = 0] = _ref;
    let {
      roundUp = false
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const round = function (value) {
      return roundUp ? Math.ceil(value) : Math.floor(value);
    };
    let second = `00${round(milliseconds / 1000 % 60)}`.substr(-2, 2);
    let minute = Math.floor(milliseconds / (1000 * 60) % 60) || '0';
    let hour = Math.floor(milliseconds / (1000 * 60 * 60) % 24);
    if (hour > 0 && minute.length === 1) {
      minute = `0${minute}`;
    }
    return [hour, minute, second].filter(Boolean).join(':');
  }
  var _default = (0, _helper.helper)(duration);
  _exports.default = _default;
});