define("audio/components/audio-recorder/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "ember-intl", "Modernizr", "moment", "showbie-paywall/services/paywall", "@ember/object/compat", "audio/components/audio-recorder/template"], function (_exports, _component, _object, _computed, _service, _emberIntl, _Modernizr, _moment, _paywall, _compat, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-actions-hash, ember/no-classic-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-get, ember/require-tagless-components */
  /**
   * @module audio
   */
  /**
   * @class AudioRecorder
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    audio: (0, _service.inject)('audio-recording'),
    currentUser: (0, _service.inject)(),
    features: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    notify: (0, _service.inject)(),
    paywall: (0, _service.inject)(),
    redux: (0, _service.inject)(),
    store: (0, _service.inject)(),
    system: (0, _service.inject)(),
    layout: _template.default,
    classNames: ['audio-recorder'],
    classNameBindings: ['isMini', 'isPlaying', 'isRecording', 'isSaving'],
    isPlaying: false,
    isRecording: false,
    isSaving: false,
    didReachMaxDuration: false,
    /**
     * Current playback position, in milliseconds.
     * @prop {number} currentPosition
     */
    currentPosition: 0,
    /**
     * Current recording duration, in milliseconds. Provided to the
     * `recording-time` component. This starts from `recordedLength` and
     * is incremented during recording.
     *
     * @prop {number} currentDuration
     */
    currentDuration: 0,
    /**
     * An array of audio Blobs filled by incremental recording.
     * @prop {Array} recordedChunks
     */
    recordedChunks: null,
    /**
     * The total duration of recorded audio.
     * @prop {number} recordedAudio
     */
    recordedLength: 0,
    /**
     * Action hook called when recording starts.
     * @return {void}
     */
    onRecordingStarted() {},
    /**
     * Action hook called when recording is paused or stopped.
     * @param  {Blob} blob The audio blob recorded up to this point.
     * @return {void}
     */
    onRecordingStopped( /* blob */) {},
    unsupportedBrowserTitle: (0, _computed.readOnly)('audio.unsupportedBrowserTitle'),
    currentUserIsTeacher: (0, _computed.readOnly)('currentUser.user.isTeacher'),
    maxAudioLength: (0, _compat.dependentKeyCompat)({
      get() {
        return this.redux.getState().mediaLimits.audio.maxDuration;
      }
    }),
    durationNearingLimit: (0, _compat.dependentKeyCompat)({
      get() {
        return this.redux.getState().mediaLimits.audio.durationWarning;
      }
    }),
    hasPremiumMediaLimits: (0, _compat.dependentKeyCompat)({
      get() {
        return this.redux.getState().mediaLimits.hasPremiumMediaLimits;
      }
    }),
    userMediaSupported: (0, _object.computed)('features.webkitVoiceNotesEnabled', function () {
      return !!(_Modernizr.default.getusermedia && (window.AudioContext || this.features.isEnabled('webkitVoiceNotesEnabled') && window.webkitAudioContext));
    }),
    /**
     * Rewind button should be disabled during recording, or when at the
     * beginning of playback.
     *
     * @prop {boolean} disableRew
     */
    disableRew: (0, _object.computed)('isRecording', 'isSaving', 'currentPosition', function () {
      const isRecording = (0, _object.get)(this, 'isRecording');
      const isSaving = (0, _object.get)(this, 'isSaving');
      const currentPosition = (0, _object.get)(this, 'currentPosition');
      return !currentPosition || isRecording || isSaving;
    }),
    /**
     * Forward button should be disabled during recording, or when at
     * the end of playback.
     *
     * `currentPosition` & `recordedLength` can differ by a few
     * milliseconds so they are rounded down before testing equality.
     *
     * @prop {boolean} disableFwd
     */
    disableFwd: (0, _object.computed)('isRecording', 'isSaving', 'currentPosition', 'recordedLength', function () {
      const isRecording = (0, _object.get)(this, 'isRecording');
      const isSaving = (0, _object.get)(this, 'isSaving');
      const currentPosition = (0, _object.get)(this, 'currentPosition');
      const recordedLength = (0, _object.get)(this, 'recordedLength');
      let currentPos = Math.floor(currentPosition);
      let totalLength = Math.floor(recordedLength);
      return currentPos === totalLength || isRecording || isSaving;
    }),
    /**
     * Record button should be disabled during playback, or when both
     * the Rewind and Forward buttons are ENabled (i.e.: when playback
     * is paused). This property is also modified when `maxDuration` is
     * reached to prevent further recording chunks.
     *
     * @prop {boolean} disableRec
     */
    disableRec: (0, _object.computed)('isPlaying', 'isSaving', 'didReachMaxDuration', 'disableRew', 'disableFwd', 'currentPosition', function () {
      const isPlaying = (0, _object.get)(this, 'isPlaying');
      const isSaving = (0, _object.get)(this, 'isSaving');
      const didReachMaxDuration = (0, _object.get)(this, 'didReachMaxDuration');
      const disableRew = (0, _object.get)(this, 'disableRew');
      const disableFwd = (0, _object.get)(this, 'disableFwd');
      const currentPosition = (0, _object.get)(this, 'currentPosition');
      if (!currentPosition) return false;

      /**
       * Disable Record button when:
       *   Playing back ...OR
       *   Both Rew & Fwd buttons are NOT disabled ...OR
       *   Max recording length reached ...OR
       *   When saving/uploading
       */
      return isPlaying || !disableRew && !disableFwd || didReachMaxDuration || isSaving;
    }),
    maxDurationReachedMessageTitle: (0, _emberIntl.t)('Voice Notes Longer than {count} Minute(s)', {
      count: 'maxDurationMinutes'
    }),
    maxDurationReachedMessageContent: (0, _emberIntl.t)('You can record voice notes up to {count} minute(s) long in the pro edition of Showbie. Your classroom is currently using the basic edition.', {
      count: 'premiumDurationLimitMinutes'
    }),
    premiumDurationLimitMinutes: (0, _object.computed)(function () {
      // @TODO set this data in redux to avoid injecting `service:system`
      return (0, _object.get)(this, 'system').getVal('premiumVoiceNoteLength') / 60;
    }),
    maxDuration: (0, _object.computed)('maxAudioLength', function () {
      return _moment.default.duration((0, _object.get)(this, 'maxAudioLength'), 'seconds');
    }),
    maxDurationMinutes: (0, _object.computed)('maxDuration', function () {
      return (0, _object.get)(this, 'maxDuration').asMinutes();
    }),
    maxDurationMilliseconds: (0, _object.computed)('maxDuration', function () {
      return (0, _object.get)(this, 'maxDuration').asMilliseconds();
    }),
    maxDurationWarning: (0, _object.computed)('durationNearingLimit', function () {
      return (0, _object.get)(this, 'durationNearingLimit') * 1000;
    }),
    /**
     * How many milliseconds are available for recording.
     *
     * This takes the current position into account to ensure that
     * `audio-recorder` gets an appropriate duration limit regardless
     * of when the user resumes recording.
     *
     * @prop {number} recordedLengthRemaining
     */
    recordedLengthRemaining: (0, _object.computed)('maxDuration', 'currentDuration', 'currentPosition', function () {
      let {
        maxDuration,
        currentDuration,
        currentPosition
      } = (0, _object.getProperties)(this, 'maxDuration', 'currentDuration', 'currentPosition');
      if (currentPosition === 0) {
        return maxDuration;
      }
      let current = _moment.default.duration(currentDuration, 'ms');
      let max = _moment.default.duration(maxDuration, 'ms');

      // recording-time expects a positive integer
      return current.subtract(max).as('ms') * -1;
    }),
    approachingMaxDuration: (0, _object.computed)('recordedLengthRemaining', 'isRecording', 'maxDurationWarning', function () {
      let lengthRemaining = (0, _object.get)(this, 'recordedLengthRemaining');
      let durationWarning = (0, _object.get)(this, 'maxDurationWarning');
      return (0, _object.get)(this, 'isRecording') && lengthRemaining <= durationWarning;
    }),
    /**
     * An audio Blob object created from the array of Blobs in
     * `recordedChunks` to provide to the playback component.
     *
     * @prop {Blob} buffer
     */
    buffer: (0, _object.computed)('recordedChunks.[]', function () {
      let chunks = (0, _object.get)(this, 'recordedChunks');
      if (chunks.length > 0) {
        return new Blob(chunks, {
          type: 'audio/mp3'
        });
      }
      return;
    }),
    showPosition: (0, _object.computed)('currentPosition', 'currentDuration', 'isRecording', function () {
      const currentPosition = (0, _object.get)(this, 'currentPosition');
      const currentDuration = (0, _object.get)(this, 'currentDuration');
      const isRecording = (0, _object.get)(this, 'isRecording');
      return !(isRecording || currentPosition === currentDuration);
    }),
    init() {
      this._super(...arguments);
      let NativeAudioContext = window.AudioContext || window.webkitAudioContext;
      if (NativeAudioContext) {
        this.audioContext = new NativeAudioContext();
        (0, _object.set)(this, 'primaryAudioNode', this.audioContext.createGain());
        (0, _object.set)(this, 'recordedChunks', []);
      }
    },
    willDestroyElement() {
      this._super(...arguments);

      // Check for AudioContext in case of an unsupported browser.
      if (this.audioContext) {
        this.audioContext.close();
      }
    },
    actions: {
      connectToAudioNode(node) {
        node.connect((0, _object.get)(this, 'primaryAudioNode'));
      },
      setRecordingLength(length) {
        let recordedLength = (0, _object.get)(this, 'recordedLength') + length;
        (0, _object.setProperties)(this, {
          currentDuration: recordedLength,
          currentPosition: recordedLength
        });
      },
      recordingStarted() {
        const currentPos = (0, _object.get)(this, 'currentPosition');

        /**
         * If recording starts from the beginning of time, reset
         * everything back to one.
         */
        if (!currentPos) {
          (0, _object.setProperties)(this, {
            currentDuration: 0,
            recordedChunks: [],
            recordedLength: 0,
            didReachMaxDuration: false
          });
        }
        (0, _object.setProperties)(this, {
          isRecording: true
        });
        this.onRecordingStarted();
      },
      recordingStopped(blob, duration) {
        let existingData = (0, _object.get)(this, 'recordedChunks');
        let length = (0, _object.get)(this, 'recordedLength') + duration;
        existingData.pushObject(blob);
        (0, _object.setProperties)(this, {
          currentDuration: length,
          currentPosition: length,
          recordedLength: length,
          isRecording: false
        });
        this.onRecordingStopped((0, _object.get)(this, 'buffer'));
      },
      /**
       * Handle recording reaching the maximum allowed duration.
       *
       * Note that in premium contexts, we don't show any
       * messaging when the recording limit is hit.
       * @return {void}
       */
      maxDurationReached() {
        (0, _object.set)(this, 'didReachMaxDuration', true);
        if (!(0, _object.get)(this, 'hasPremiumMediaLimits')) {
          this.showMaxDurationReached();
        }
      },
      playbackStarted() {
        (0, _object.setProperties)(this, {
          isPlaying: true
        });
      },
      playbackPaused() {
        (0, _object.setProperties)(this, {
          isPlaying: false
        });
      },
      playbackComplete() {
        (0, _object.setProperties)(this, {
          isPlaying: false,
          currentPosition: (0, _object.get)(this, 'currentDuration')
        });
      }
    },
    showMaxDurationReached() {
      let isTeacher = (0, _object.get)(this, 'currentUserIsTeacher');
      let title = (0, _object.get)(this, 'maxDurationReachedMessageTitle');
      let content = (0, _object.get)(this, 'maxDurationReachedMessageContent');
      let message = `${title}\n${content}`;
      if (isTeacher) {
        (0, _object.get)(this, 'paywall').show(_paywall.proFeatures.VoicenoteLimit);
      } else {
        return (0, _object.get)(this, 'notify').alert(message);
      }
    }
  });
  _exports.default = _default;
});