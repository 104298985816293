define("ember-svg-jar/inlined/arrow-right-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.574 6.167l.077.067 5.359 5.213a.784.784 0 01.068 1.054l-.068.076-5.334 5.19a.837.837 0 01-1.161 0 .783.783 0 01-.068-1.055l.068-.075 3.994-3.887H4.25a.75.75 0 01-.102-1.493l.102-.007h13.236L13.49 7.363a.783.783 0 010-1.13.838.838 0 011.084-.066z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});