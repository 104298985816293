define("showbie-master-panel/components/panel-actions/component", ["exports", "@ember/template-factory", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _templateFactory, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  var _default = _component.default.extend({
    layout: (0, _templateFactory.createTemplateFactory)(
    /*
      
        {{yield
          (hash
            select=(component "panel-actions/x-select" showSelectAll=this.showSelectAll)
            sort=(component "panel-actions/x-sort")
          )
          (hash
            notLocked=this.notLocked
            notViewOnly=this.notViewOnly
            notEditable=this.notEditable
            notArchived=this.notArchived
            notActive=this.notActive
            notHidden=this.notHidden
            showUnlockIcon=this.showUnlockIcon
          )
        }}
      
    */
    {
      "id": "BrwCxenw",
      "block": "[[[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"select\",\"sort\"],[[50,\"panel-actions/x-select\",0,null,[[\"showSelectAll\"],[[30,0,[\"showSelectAll\"]]]]],[50,\"panel-actions/x-sort\",0,null,null]]]],[28,[37,1],null,[[\"notLocked\",\"notViewOnly\",\"notEditable\",\"notArchived\",\"notActive\",\"notHidden\",\"showUnlockIcon\"],[[30,0,[\"notLocked\"]],[30,0,[\"notViewOnly\"]],[30,0,[\"notEditable\"]],[30,0,[\"notArchived\"]],[30,0,[\"notActive\"]],[30,0,[\"notHidden\"]],[30,0,[\"showUnlockIcon\"]]]]]]],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
      "moduleName": "/Users/keithkoshman/Dev/showbie/web/packages/showbie/showbie-master-panel/components/panel-actions/component.js",
      "isStrictMode": false
    }),
    classNames: ['panel-actions'],
    assignments: null,
    maxSelected: 0,
    notArchived: (0, _computed.filterBy)('assignments', 'isActive'),
    notActive: (0, _computed.filterBy)('assignments', 'isArchived'),
    notHidden: (0, _computed.filterBy)('assignments', 'isHidden', false),
    notLocked: (0, _computed.filterBy)('assignments', 'isLocked', false),
    notViewOnly: (0, _computed.filterBy)('assignments', 'isReadOnly', false),
    notEditable: (0, _computed.filterBy)('assignments', 'isEditable', false),
    showSelectAll: (0, _object.computed)('assignments.[]', 'maxSelected', function () {
      let selected = (0, _object.get)(this, 'assignments').length;
      let max = (0, _object.get)(this, 'maxSelected');
      return selected < max;
    }),
    showUnlockIcon: (0, _object.computed)('assignments.@each.{isLocked,isEditable}', function () {
      let locked = (0, _object.get)(this, 'assignments').filterBy('isLocked').length;
      let editable = (0, _object.get)(this, 'assignments').filterBy('isEditable').length;
      return locked > 0 && editable === 0;
    })
  });
  _exports.default = _default;
});