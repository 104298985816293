define("showbie-paywall/services/paywall", ["exports", "@ember/object/computed", "@ember/debug", "@ember/service", "@ember/object", "@ember/string", "showbie-paywall/paywall-slides", "showbie/utils/analytics-naming"], function (_exports, _computed, _debug, _service, _object, _string, _paywallSlides, _analyticsNaming) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.proFeatures = _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/require-computed-property-dependencies */

  const ProFeatures = _object.default.extend({
    AssignmentCount: 'Assignment_Count',
    AssignmentExpiry: 'Assignment_Expiry',
    CoTeaching: 'Co_Teaching',
    ClassDiscussion: 'Class_Discussion',
    Portfolio: 'Portfolio',
    Groups: 'Groups',
    Gradebook: 'Gradebook',
    ParentAccess: 'Parent_Access',
    VideoLimit: 'Video_Limit',
    VoicenoteLimit: 'Voicenote_Limit',
    FilesizeLimit: 'Filesize_Limit',
    ClassFolders: 'Class_Folders',
    VideoChat: 'Video_Chat',
    ArchivedVisibility: 'Archived_Visibility',
    unknownProperty(name) {
      (false && !(false) && (0, _debug.assert)(`'${name}' is not a valid pro feature in the Showbie paywall.`));
    }
  });
  const proFeatures = ProFeatures.create();
  _exports.proFeatures = proFeatures;
  var _default = _service.default.extend({
    currentUserService: (0, _service.inject)('current-user'),
    intl: (0, _service.inject)(),
    licence: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    notify: (0, _service.inject)(),
    store: (0, _service.inject)(),
    currentUser: (0, _computed.readOnly)('currentUserService.user'),
    introSlideUpgrade: _paywallSlides.INTRO_SLIDES.upgrade,
    slides: _paywallSlides.FEATURE_SLIDES,
    /**
     * To be overridden by the trigger component for the paywall. Used for changing order
     * of slides to highlight certain features, or hiding the intro slide.
     * @private
     */
    orderingContext: '',
    /**
     * Should the generic intro slide be displayed before the pro feature slides?
     * @type {Boolean}
     * @private
     */
    showIntro: true,
    /**
     * Is the paywall currently visible?
     * @type {Boolean}
     * @private
     */
    isVisible: false,
    /**
     * Show the video chat paywall which is different from the standard paywall
     * @type {Boolean}
     */

    showVideoChatPaywall: false,
    /**
     * Determine if a certain `feature` is allowed to be used.
     * Note: at this time, we are not taking `feature` into account and this is purely based on
     * the current user's `licence.premium` attribute.
     *
     * There are currently two main exceptions to this rule: Groups and Class Discussion.
     * These are both based on `{group,class}.meta.premium` instead of the current user's licence.
     *
     * Usage:
     * ```
     *  if(!paywall.allow(CoTeaching)) {
     *    paywall.show(CoTeaching)
     *  }
     * ```
     * @param {String} feature
     * @return {Boolean}
     * @public
     */
    allow(feature) {
      let hook = `allow${(0, _string.classify)(feature)}`;
      if (this.currentUser?.isTeacher) {
        if (typeof this[hook] === 'function') {
          for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            params[_key - 1] = arguments[_key];
          }
          return this[hook](this.licence, this.currentUser, ...params);
        }
        return this.licence?.isPro;
      }
      return true;
    },
    /**
     * Check if a Class has should allow access to the Class Discussion feature.
     * @param  {ClassModel} klass A Class model
     * @return {Boolean}    true if Class Discussion should be a available
     * @public
     */
    allowInClassDiscussion() {
      let klass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return !!klass.hasPremiumOwner;
    },
    /**
     * Display the paywall.
     * By default the calling this method automatically logs a PAYWALL_TOUCHED analytic event.
     *
     * @method show
     * @param {String} slug Name of the Paywall slide to show first
     * @param {Boolean} trackPaywallTouch If true, automatically track this call as a paywall touch in analytics
     * @public
     */
    show() {
      let slug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let trackPaywallTouch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (!this.currentUser.isTeacher || this.licence.isPro) {
        return;
      }
      if (!slug) {
        (0, _object.set)(this, 'showIntro', true);
      } else {
        (0, _object.set)(this, 'showIntro', false);
      }
      if (slug === proFeatures.VideoChat) {
        (0, _object.set)(this, 'showVideoChatPaywall', true);
      } else {
        (0, _object.set)(this, 'orderingContext', slug);
      }
      (0, _object.set)(this, 'isVisible', true);
      if (trackPaywallTouch) {
        this.trackPaywallTouch(slug);
      }
    },
    /**
     * Dismiss the paywall.
     * @public
     */
    hide() {
      (0, _object.set)(this, 'isVisible', false);
      if (this.showVideoChatPaywall) (0, _object.set)(this, 'showVideoChatPaywall', false);
    },
    /**
     * Track what feature has triggered the paywall.
     *
     * In most cases this shouldn't be called manually as it is called by default in `show`.
     * However, if you need to log the PAYWALL_TOUCHED separately, you can do this:
     *
     * ```
     * paywall.trackPaywallTouch(AssignmentCount);
     * // ... and if you need paywall without automatically analytic tracking
     * paywall.show(AssignmentCount, false);
     * ```
     *
     * @public
     * @see AnalyticSerializer; it transforms the `proFeature` attribute.
     */
    trackPaywallTouch(key) {
      let store = this.store;
      let proFeature = key || this.orderingContext || 'Upgrade_Badge';
      let analytic = store.createRecord('analytic', {
        event: 'PAYWALL_TOUCHED',
        proFeature
      });
      analytic.save().catch(() => {
        (0, _debug.debug)('Failed to save analytic model from PaywallService#trackPaywallTouch');
      });
      this.metrics.trackEvent('ShowbieAmplitude', {
        event: 'paywall__triggered',
        proFeature: (0, _analyticsNaming.default)(proFeature) || proFeature
      });
    },
    /**
     * Display the "assignment limit reached" alert.
     * This pro feature requires that a special explanation message be displayed *before* the paywall
     * itself is displayed. Because of this special case, the PAYWALL_TOUCHED analytic needs to be tracked
     * when this message is displayed, not when the paywall is displayed.
     * @return {Promise}
     * @public
     */
    showAssignmentLimitAlert() {
      let intl = this.intl;
      let licence = this.licence;
      let notify = this.notify;
      let slug = proFeatures.AssignmentCount;
      let assignmentLimit = licence.activeAssignmentLimit;
      let message = intl.t('You already have {a1} assignments on the go. Upgrade to Showbie Pro to get unlimited active assignments, or archive an existing assignment to make room for more.', {
        a1: assignmentLimit
      });

      // because the user can cancel out of this confirm popup, we track the paywall touch eagerly
      // instead of when paywall is actually displayed, which only happens if they confirm.
      this.trackPaywallTouch(slug);
      return notify.confirm(message).then(() => this.show(slug, false)).catch(() => {}); // swallow error if user cancels the confirm popup
    },

    /**
     * Property that's called by the slideshow for determining what slides to show.
     * @private
     *
     * Dependent on the `orderingContext`, and `showIntro` properties of the paywall.
     */
    assembledSlides: (0, _object.computed)('slides', 'orderingContext', 'showIntro', function () {
      let context = this.orderingContext;
      let slides = [].concat(this.slides);
      let showIntro = this.showIntro;
      let introSlide = this.introSlideUpgrade;
      let assembled = [];
      if (showIntro) {
        assembled.push(introSlide);
      }
      if (context) {
        let contextIndex = 0;
        let first = slides.find((value, index) => {
          if (value.slug === context) {
            contextIndex = index;
            return true;
          }
          return false;
        });
        slides.splice(contextIndex, 1);
        assembled = assembled.concat(first);
      }
      assembled = assembled.concat(slides);
      assembled.forEach((item, index) => {
        item.index = index;
      });
      return assembled;
    })
  });
  _exports.default = _default;
});