define("showbie-master-panel/components/assignment-duedate/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.output}}
  */
  {
    "id": "yUNeMnXN",
    "block": "[[[1,[30,0,[\"output\"]]]],[],false,[]]",
    "moduleName": "showbie-master-panel/components/assignment-duedate/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});