define("showbie-master-panel/components/cell-symbol/component", ["exports", "@ember/component", "showbie-master-panel/components/cell-symbol/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components */
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['cell-symbol']
  });
  _exports.default = _default;
});