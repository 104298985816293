define("ember-svg-jar/inlined/button-icon-unarchive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M18.654 7.256l3.16 3.608a.752.752 0 01-.07 1.06.752.752 0 01-1.058-.072l-1.936-2.21v6.25a.75.75 0 01-1.5 0V9.68l-1.963 2.01a.75.75 0 11-1.074-1.049l3.34-3.416A.708.708 0 0118.108 7a.75.75 0 01.544.256zM25.75 14H21v1.5h4.75c.413 0 .75.337.75.75v10.5c0 .413-.337.75-.75.75h-15.5a.752.752 0 01-.75-.75v-10.5c0-.413.337-.75.75-.75H15V14h-4.75C9.01 14 8 15.01 8 16.25v10.5C8 27.99 9.01 29 10.25 29h15.5c1.24 0 2.25-1.01 2.25-2.25v-10.5c0-1.24-1.01-2.25-2.25-2.25zM15 19.5c-.275 0-.5.225-.5.5s.225.5.5.5h6c.275 0 .5-.225.5-.5s-.225-.5-.5-.5h-6zm6 2.5h-6c-1.103 0-2-.897-2-2s.897-2 2-2h6c1.103 0 2 .897 2 2s-.897 2-2 2z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});