define("ember-svg-jar/inlined/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.483 11.459V.704h.705c.78 0 1.57.06 2.37.18.81.12 1.63.305 2.46.555.8.25 1.555.44 2.265.57.72.12 1.39.19 2.01.21v6.915h-.765c-.44 0-.945-.045-1.515-.135-.56-.1-1.195-.245-1.905-.435-.68-.19-1.325-.33-1.935-.42-.6-.09-1.145-.135-1.635-.135h-.45c-.05 0-.125.005-.225.015v3.435H.483zm8.415-3.735v-4.17a11.6 11.6 0 01-1.515-.285c-.55-.15-1.14-.32-1.77-.51a14.29 14.29 0 00-1.905-.465c-.63-.12-1.245-.19-1.845-.21v4.545c.09-.02.21-.03.36-.03.15-.01.305-.015.465-.015.52 0 1.09.05 1.71.15.62.1 1.29.25 2.01.45.6.16 1.1.285 1.5.375.4.08.73.135.99.165z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "11",
      "height": "12",
      "viewBox": "0 0 11 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});