define("ember-svg-jar/inlined/expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-expand</title><path d=\"M7.363 14.76a.783.783 0 01-1.13 0 .838.838 0 01-.066-1.084l.067-.077 5.213-5.359a.783.783 0 011.054-.068l.076.068 5.19 5.334c.31.32.31.84 0 1.161a.783.783 0 01-1.055.068l-.075-.068-4.625-4.753-4.649 4.778z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});