define("ember-svg-jar/inlined/button-icon-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 10.51h14v.99H10v-.99zm0-7h14v.98H10v-.98zm0 14h14v.99H10v-.99zm-2.26-2.52l-4.58 4.59-2.11-2.12-.7.72 2.82 2.83 5.28-5.31zm-4.58-2.42l-2.11-2.12-.7.72 2.82 2.82 5.28-5.31-.71-.71zM8.45 1.68L7.74.97l-4.58 4.6-2.11-2.12-.7.72 2.82 2.82z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 23"
    }
  };
  _exports.default = _default;
});