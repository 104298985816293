define("ember-svg-jar/inlined/pro-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"34\" height=\"18\" rx=\"3\" fill=\"#009FE8\"/><path d=\"M3.936 4.796h3.419c.477 0 .927.043 1.352.13.425.087.793.238 1.105.455.32.208.572.494.754.858.19.355.286.806.286 1.352 0 .537-.087.988-.26 1.352a2.218 2.218 0 01-.715.871 2.977 2.977 0 01-1.079.468c-.416.087-.867.13-1.352.13H5.964V14H3.936V4.796zm2.028 3.9h1.352c.182 0 .355-.017.52-.052.173-.035.325-.091.455-.169a.934.934 0 00.325-.338c.087-.147.13-.33.13-.546 0-.234-.056-.42-.169-.559a1 1 0 00-.416-.338 1.608 1.608 0 00-.559-.143 4.826 4.826 0 00-.598-.039h-1.04v2.184zm6.16-3.9h3.563c.467 0 .91.048 1.325.143.425.087.793.238 1.105.455.321.208.572.494.755.858.19.355.285.802.285 1.339 0 .65-.169 1.205-.506 1.664-.338.45-.832.737-1.482.858L19.509 14h-2.431l-1.925-3.679h-1V14h-2.029V4.796zm2.028 3.809h1.197c.181 0 .372-.004.572-.013a2.11 2.11 0 00.559-.117.96.96 0 00.402-.312c.113-.139.17-.334.17-.585 0-.234-.048-.42-.143-.559a.903.903 0 00-.364-.312 1.513 1.513 0 00-.508-.156 4.136 4.136 0 00-.558-.039h-1.326v2.093zm5.908.793c0-.737.122-1.404.364-2.002a4.398 4.398 0 011.04-1.521 4.49 4.49 0 011.573-.975 5.67 5.67 0 011.99-.338c.718 0 1.381.113 1.988.338a4.49 4.49 0 011.573.975c.442.416.784.923 1.027 1.521.252.598.377 1.265.377 2.002 0 .737-.126 1.404-.377 2.002a4.384 4.384 0 01-1.027 1.534 4.605 4.605 0 01-1.573.962 5.671 5.671 0 01-1.989.338 5.67 5.67 0 01-1.989-.338 4.604 4.604 0 01-1.573-.962 4.535 4.535 0 01-1.04-1.534c-.242-.598-.364-1.265-.364-2.002zm2.106 0c0 .433.065.832.195 1.196.139.355.334.667.585.936.252.26.55.464.897.611.355.147.75.221 1.183.221.433 0 .823-.074 1.17-.221.355-.147.659-.351.91-.611.252-.269.442-.58.572-.936.139-.364.208-.763.208-1.196 0-.425-.07-.819-.208-1.183a2.572 2.572 0 00-.572-.936 2.585 2.585 0 00-.91-.624 2.964 2.964 0 00-1.17-.221 3.06 3.06 0 00-1.183.221 2.612 2.612 0 00-.897.624 2.72 2.72 0 00-.585.936c-.13.364-.195.758-.195 1.183z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "34",
      "height": "18",
      "viewBox": "0 0 34 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});