define("ember-svg-jar/inlined/archive-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M26.75 8C27.99 8 29 9.01 29 10.25v2.5c0 .778-.397 1.465-1 1.869V25.75c0 1.24-1.01 2.25-2.25 2.25h-15.5C9.01 28 8 26.99 8 25.75V14.619a2.247 2.247 0 01-1-1.869v-2.5C7 9.01 8.01 8 9.25 8zm-.25 7h-17v10.75c0 .413.337.75.75.75h15.5c.413 0 .75-.337.75-.75V15zM21 17c1.103 0 2 .897 2 2s-.897 2-2 2h-6c-1.103 0-2-.897-2-2s.897-2 2-2zm0 1.5h-6c-.275 0-.5.225-.5.5s.225.5.5.5h6c.275 0 .5-.225.5-.5s-.225-.5-.5-.5zm5.75-9H9.25a.752.752 0 00-.75.75v2.5c0 .413.337.75.75.75h17.5c.413 0 .75-.337.75-.75v-2.5a.752.752 0 00-.75-.75z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});