define("torii/providers/facebook-oauth2", ["exports", "torii/configuration", "torii/providers/oauth2-code", "@ember/object"], function (_exports, _configuration, _oauth2Code, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get, ember/avoid-leaking-state-in-ember-objects */
  var _default = _oauth2Code.default.extend({
    name: 'facebook-oauth2',
    baseUrl: (0, _object.computed)('apiVersion', function () {
      if (this.get('apiVersion')) {
        // Facebook API version must be of shape 'vx.x'.
        const FACEBOOK_API_VERSION_REGEX = /^v(\d)\.(\d)$/;
        if (!FACEBOOK_API_VERSION_REGEX.test(this.get('apiVersion'))) {
          throw new Error(`The Facebook API version must be of the shape 'vX.X'`);
        }
        return `https://www.facebook.com/${this.get('apiVersion')}/dialog/oauth`;
      } else {
        return `https://www.facebook.com/dialog/oauth`;
      }
    }),
    // Additional url params that this provider requires
    requiredUrlParams: ['display'],
    responseParams: ['code', 'state'],
    scope: (0, _configuration.configurable)('scope', 'email'),
    apiVersion: (0, _configuration.configurable)('apiVersion', null),
    display: 'popup',
    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      // A hack that allows redirectUri to be configurable
      // but default to the superclass
      return this._super();
    }),
    open() {
      return this._super().then(function (authData) {
        if (authData.authorizationCode && authData.authorizationCode === '200') {
          // indication that the user hit 'cancel', not 'ok'
          throw new Error('User canceled authorization');
        }
        return authData;
      });
    }
  });
  _exports.default = _default;
});