define("showbie-live/reducers/store-reducer", ["exports", "showbie-live/reducers/reducer"], function (_exports, _reducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/classic-decorator-hooks */

  class StoreReducer extends _reducer.default {
    constructor(store) {
      super();
      this.store = store;
    }
    handle(data) {
      return super.handle(data, this.store);
    }
  }
  _exports.default = StoreReducer;
});