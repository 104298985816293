define("intercom/instance-initializers/intercom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    let intercom = appInstance.lookup('service:intercom');
    let applicationConfig = appInstance.resolveRegistration('config:environment');
    intercom.configure(applicationConfig);
  }
  var _default = {
    name: 'intercom',
    initialize
  };
  _exports.default = _default;
});