define("showbie-master-panel/components/cell-container/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let "cell-container" as |cellClass|}}
    {{#if @linkToRoute}}
      {{! TODO, can we clean this up...?}}
      {{#if @linkToQuery}}
        <LinkTo
          @route={{@linkToRoute}}
          @query={{@linkToQuery}}
          @disabled={{@disabled}}
          class={{cellClass}}
        >{{yield}}</LinkTo>
      {{else if @linkToModels}}
        <LinkTo
          @route={{@linkToRoute}}
          @models={{@linkToModels}}
          @disabled={{@disabled}}
          class={{cellClass}}
        >{{yield}}</LinkTo>
      {{else}}
        <LinkTo
          @route={{@linkToRoute}}
          @disabled={{@disabled}}
          class={{cellClass}}
        >{{yield}}</LinkTo>
      {{/if}}
    {{else}}
      <div class={{cellClass}}>{{yield}}</div>
    {{/if}}
  {{/let}}
  */
  {
    "id": "5KMaP0VV",
    "block": "[[[44,[\"cell-container\"],[[[41,[30,2],[[[41,[30,3],[[[1,\"      \"],[8,[39,2],[[16,0,[30,1]]],[[\"@route\",\"@query\",\"@disabled\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[18,6,null]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"      \"],[8,[39,2],[[16,0,[30,1]]],[[\"@route\",\"@models\",\"@disabled\"],[[30,2],[30,5],[30,4]]],[[\"default\"],[[[[18,6,null]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],[[16,0,[30,1]]],[[\"@route\",\"@disabled\"],[[30,2],[30,4]]],[[\"default\"],[[[[18,6,null]],[]]]]],[1,\"\\n    \"]],[]]]],[]]]],[]],[[[1,\"    \"],[10,0],[15,0,[30,1]],[12],[18,6,null],[13],[1,\"\\n\"]],[]]]],[1]]]],[\"cellClass\",\"@linkToRoute\",\"@linkToQuery\",\"@disabled\",\"@linkToModels\",\"&default\"],false,[\"let\",\"if\",\"link-to\",\"yield\"]]",
    "moduleName": "showbie-master-panel/components/cell-container/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});