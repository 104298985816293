define("ember-svg-jar/inlined/feedback-guide-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"15\" cy=\"15\" r=\"15\" fill=\"#107CC0\"/><path d=\"M14.67 4.53l1.17 4.68 3.15 1.74-3.15 1.73-1.17 4.69-1.17-4.69-3.15-1.73L13.5 9.2l1.17-4.68zm6.75 8.95l.65 2.6 1.75.95-1.75.96-.65 2.6-.64-2.6-1.75-.96 1.75-.96.64-2.6zM9.65 16.14l.65 2.6 1.74.95-1.74.96-.65 2.6-.65-2.6-1.75-.96L9 18.73l.65-2.6z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});