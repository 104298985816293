define("ember-svg-jar/inlined/restore-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 13v1.5h-4.75a.752.752 0 00-.75.75v10.5c0 .413.337.75.75.75h15.5c.413 0 .75-.337.75-.75v-10.5a.752.752 0 00-.75-.75H21V13h4.75c1.24 0 2.25 1.01 2.25 2.25v10.5c0 1.24-1.01 2.25-2.25 2.25h-15.5C9.01 28 8 26.99 8 25.75v-10.5C8 14.01 9.01 13 10.25 13H15zm6 4c1.103 0 2 .897 2 2s-.897 2-2 2h-6c-1.103 0-2-.897-2-2s.897-2 2-2h6zm0 1.5h-6c-.275 0-.5.225-.5.5s.225.5.5.5h6c.275 0 .5-.225.5-.5s-.225-.5-.5-.5zM18.11 6a.75.75 0 01.544.256l3.16 3.608a.752.752 0 01-.07 1.06.752.752 0 01-1.058-.072l-1.936-2.21v6.25a.75.75 0 01-1.5 0V8.68l-1.963 2.01a.75.75 0 11-1.074-1.049l3.34-3.416A.708.708 0 0118.108 6z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});