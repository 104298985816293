define("showbie-master-panel/helpers/class-meta", ["exports", "@ember/object/computed", "@ember/object", "@ember/component/helper", "@ember/service", "@ember/utils", "showbie-utils/utils/assignment-counts"], function (_exports, _computed, _object, _helper, _service, _utils, _assignmentCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classMeta = classMeta;
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get */

  /**
   * Generate a translated version of the class metadata
   *
   * @param  {Class} klass
   * @param  {Assignment[]} assignments
   * @param  {User} user
   * @param  {Object} services
   * @param  {Delorean} services.delorean
   * @param  {I18n} services.intl
   * @param  {MetaStore} services.metaStore
   *
   * @return {String}
   * @public
   */
  function classMeta(klass, assignments, user, _ref) {
    let {
      delorean,
      intl,
      metaStore
    } = _ref;
    if ((0, _object.get)(klass, 'isArchived')) {
      let archivedDateStamp = (0, _object.get)(klass, 'archivedDateStamp');
      return intl.t('archived {a1}', {
        a1: delorean.timeago(archivedDateStamp)
      });
    }
    let {
      totalAssignmentsAssigned,
      totalAssignmentsCollected,
      totalStudents
    } = metaStore.find(user, klass);
    if ((0, _utils.isPresent)(assignments)) {
      totalAssignmentsAssigned = assignments.filterBy('isActive').rejectBy('isCollected').length;
    }
    if (!totalStudents) {
      let code = (0, _object.get)(klass, 'code');
      return intl.t('class code {a1}', {
        a1: code
      });
    }
    return (0, _assignmentCounts.getAssignmentCountsText)(totalAssignmentsAssigned, totalAssignmentsCollected, intl);
  }

  /**
   * Output class metadata
   * @type {Helper}
   * @param {Class} params[0]
   * @param {User} params[1] - Optional.
   * @example
   *
   * By default, it will tailor the metadata to the current user:
   *
   * `{{class-meta klass}}`
   *
   * You can also provide a user if you want:
   *
   * `{{class-meta klass contextUser}}`
   *
   * @public
   */
  var _default = _helper.default.extend({
    currentUserService: (0, _service.inject)('current-user'),
    delorean: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    metaStore: (0, _service.inject)(),
    currentUser: (0, _computed.readOnly)('currentUserService.user'),
    compute(_ref2) {
      let [klass, user = (0, _object.get)(this, 'currentUser')] = _ref2;
      let assignments = klass.hasMany('assignments').value();
      if (assignments === null) {
        // TODO: recompute if assignments is a pending promise
        // get(klass, 'assignments').then(() => this.recompute(...arguments));
      }
      return classMeta(klass, assignments, user, (0, _object.getProperties)(this, 'delorean', 'intl', 'metaStore'));
    }
  });
  _exports.default = _default;
});