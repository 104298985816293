define("torii/providers/base", ["exports", "@ember/object", "torii/lib/required-property", "torii/lib/container-utils", "torii/configuration"], function (_exports, _object, _requiredProperty, _containerUtils, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get */

  var DEFAULT_REMOTE_SERVICE_NAME = 'popup';

  /**
   * The base class for all torii providers
   * @class BaseProvider
   */
  var Base = _object.default.extend({
    /**
     * The name of the provider
     * @property {string} name
     */
    name: (0, _requiredProperty.default)(),
    /**
     * The name of the configuration property
     * that holds config information for this provider.
     * @property {string} configNamespace
     */
    configNamespace: (0, _object.computed)('name', function () {
      return 'providers.' + this.get('name');
    }),
    popup: (0, _object.computed)('configuredRemoteServiceName', function () {
      var owner = (0, _containerUtils.getOwner)(this);
      var remoteServiceName = this.get('configuredRemoteServiceName') || _configuration.default.remoteServiceName || DEFAULT_REMOTE_SERVICE_NAME;
      return owner.lookup('torii-service:' + remoteServiceName);
    }),
    configuredRemoteServiceName: (0, _configuration.configurable)('remoteServiceName', null)
  });
  var _default = Base;
  _exports.default = _default;
});