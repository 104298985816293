define("showbie-master-panel/components/master-cell/component", ["exports", "@ember/component", "@ember/object", "@ember/utils", "showbie-master-panel/components/master-cell/template"], function (_exports, _component, _object, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-tagless-components */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'li',
    classNames: ['master-cell'],
    classNameBindings: ['disabled:master-cell--disabled', 'hasAccessIcon:has-access-icon'],
    linkTo: '',
    model: null,
    disabled: false,
    init() {
      this._super(...arguments);
      let linkTo = (0, _object.get)(this, 'linkTo');
      if ((0, _utils.typeOf)(linkTo) === 'object') {
        (0, _object.set)(this, 'useLinkToWrapper', true);
      }
    },
    click() {
      if (!(0, _object.get)(this, 'disabled')) {
        this.action?.();
      }
    }
  });
  _exports.default = _default;
});