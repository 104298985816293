define("ember-svg-jar/inlined/formative-assessment-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.186 11.551a.73.73 0 01.385.957L10.587 19.5l-3.784-1.614a.73.73 0 01.572-1.342l2.443 1.042 2.411-5.65a.73.73 0 01.957-.384zm10.574.646a.73.73 0 01.948.405l1.046 2.604 2.604-1.046a.73.73 0 11.543 1.353l-2.603 1.046 1.046 2.604a.73.73 0 01-1.354.543l-1.046-2.603-2.604 1.046a.73.73 0 01-.543-1.354L24.4 15.75l-1.047-2.604a.73.73 0 01.405-.948z\"/><path fill-rule=\"evenodd\" d=\"M17.742 20.99l-2.76 1.051 2.049 5.099a2.418 2.418 0 11-4.489 1.803l-2.028-5.05-2.05.825a2.606 2.606 0 01-3.389-1.446l-3.386-8.43a2.605 2.605 0 011.446-3.388l9.278-3.728a2.606 2.606 0 013.39 1.446L18 14.643l2.198-5.469a2.606 2.606 0 013.389-1.446l9.278 3.728a2.605 2.605 0 011.447 3.389l-3.387 8.429a2.605 2.605 0 01-3.389 1.446l-2.05-.825-2.028 5.05a2.419 2.419 0 11-4.489-1.803l2.049-5.099-2.76-1.05a2.638 2.638 0 01-.26-.121 2.647 2.647 0 01-.256.118zm-.839-1.24l-3.828 1.457 2.602 6.476a.96.96 0 01-1.781.716l-2.573-6.404-3.403 1.37a1.147 1.147 0 01-1.492-.637l-3.386-8.43a1.147 1.147 0 01.637-1.49l9.278-3.729a1.147 1.147 0 011.492.637l2.766 6.884-.404 1.003a2.6 2.6 0 00.092 2.146zm1.888-.115a1.147 1.147 0 01-.627-1.488l3.387-8.429a1.147 1.147 0 011.492-.637l9.278 3.728c.588.236.873.904.637 1.492l-3.386 8.43a1.147 1.147 0 01-1.492.636l-3.403-1.37-2.573 6.404a.96.96 0 01-1.781-.715l2.602-6.477-4.134-1.574z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36"
    }
  };
  _exports.default = _default;
});