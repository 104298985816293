define("audio/components/audio-recorder/level-meter/component", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/template", "@ember/utils", "audio/components/audio-recorder/level-meter/template"], function (_exports, _component, _object, _runloop, _template, _utils, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-get, ember/require-tagless-components */
  /**
   * @module audio-recorder
   */
  /**
   * @class LevelMeter
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _template2.default,
    classNames: ['level-meter'],
    classNameBindings: ['isSaving'],
    attributeBindings: ['style'],
    minLevel: 0,
    maxLevel: 100,
    /**
     * @prop {number} [level=0]
     */
    level: 0,
    style: null,
    /**
     * Calculate the percentage of the VU meter to fill, based on
     * the `level` property value.
     *
     * @prop {number} percent
     * @protected
     * @readonly
     */
    percent: (0, _object.computed)('level', 'minLevel', 'maxLevel', 'isSaving', 'progress', function () {
      const level = (0, _object.get)(this, 'level');
      const minLevel = (0, _object.get)(this, 'minLevel');
      const maxLevel = (0, _object.get)(this, 'maxLevel');
      const isSaving = (0, _object.get)(this, 'isSaving');
      const progress = (0, _object.get)(this, 'progress');
      let value = isSaving ? progress : level;

      // icanhaz pipeline operators yet plz?
      return Math.min(Math.max((value - minLevel) / (maxLevel - minLevel), 0), 1) * 100;
    }).readOnly(),
    /**
     * Output an inline style attribute property.
     *
     * @prop {string} levelStyle
     * @private
     * @readonly
     */
    levelStyle: (0, _object.computed)('percent', function () {
      let scale = (0, _object.get)(this, 'percent') / 100;
      return (0, _template.htmlSafe)(`transform: scaleX(${scale})`);
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      if ((0, _utils.isNone)(this.element)) {
        return;
      }
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver((0, _runloop.bind)(this, this.scheduleResize));
        this.resizeObserver.observe(this.element.parentNode);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    /**
     * Force the meter width to a whole, even-numbered value.
     */
    computeMeterWidth() {
      if ((0, _utils.isNone)(this.element)) {
        return;
      }
      let parentWidth = Math.floor(this.element.parentNode.getBoundingClientRect().width);
      let initialWidth = this.element.style.maxWidth;
      let constrainedWidth = `${parentWidth - parentWidth % 2}px`;
      let widthString = (0, _template.htmlSafe)(`max-width: ${constrainedWidth}`);
      if (constrainedWidth !== initialWidth) {
        (0, _object.set)(this, 'style', widthString);
      }
    },
    scheduleResize() {
      (0, _runloop.scheduleOnce)('afterRender', this, this.computeMeterWidth);
    }
  });
  _exports.default = _default;
});