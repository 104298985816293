define("ember-svg-jar/inlined/lock-icon-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M12.25 3.93a3.41 3.41 0 00-3.4 3.42v3.42h6.8V7.35a3.407 3.407 0 00-3.4-3.42zm0-2.93a6.33 6.33 0 016.31 6.35v3.42h.48a1.46 1.46 0 011.46 1.46v8.8a1.458 1.458 0 01-1.46 1.47H5.5A1.461 1.461 0 014 21.03v-8.8a1.46 1.46 0 011.5-1.46h.48V7.35A6.33 6.33 0 0112.25 1z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});