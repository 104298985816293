define("torii/configuration", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.configurable = configurable;
  _exports.configure = configure;
  _exports.default = void 0;
  _exports.getConfiguration = getConfiguration;
  /* eslint-disable ember/require-computed-property-dependencies, ember/no-side-effects, ember/no-get */

  const NAMESPACE = 'providers';
  let configuration = {};
  function configurable(configKey, defaultValue) {
    return (0, _object.computed)(function configurableComputed() {
      // Trigger super wrapping in Ember 2.1.
      // See: https://github.com/emberjs/ember.js/pull/12359
      this._super = this._super || function () {
        throw new Error('should always have _super');
      }();
      var configNamespace = NAMESPACE + '.' + this.get('name');
      var propertyPath = configNamespace + '.' + configKey;
      let configuration = getConfiguration();
      var value = (0, _object.get)(configuration, propertyPath);
      if (typeof value === 'undefined') {
        if (typeof defaultValue !== 'undefined') {
          if (typeof defaultValue === 'function') {
            return defaultValue.call(this);
          } else {
            return defaultValue;
          }
        } else {
          throw new Error('Expected configuration value ' + configKey + ' to be defined for provider named ' + this.get('name'));
        }
      }
      return value;
    });
  }
  function configure(settings) {
    configuration = settings;
  }
  function getConfiguration() {
    return configuration;
  }
  var _default = {};
  _exports.default = _default;
});