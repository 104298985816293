define("audio/components/audio-recorder/button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="audio-button__content">
    <XIcon @symbol={{this.buttonIcon}} @height="16px" @width="16px" />
    {{#if this.includePauseIcon}}
      <XIcon
        @symbol="audio-pause"
        @height="16px"
        @width="16px"
        @data-test-audio-recorder-pause={{true}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "w5j9ZYKD",
    "block": "[[[10,0],[14,0,\"audio-button__content\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@symbol\",\"@height\",\"@width\"],[[30,0,[\"buttonIcon\"]],\"16px\",\"16px\"]],null],[1,\"\\n\"],[41,[30,0,[\"includePauseIcon\"]],[[[1,\"    \"],[8,[39,0],null,[[\"@symbol\",\"@height\",\"@width\",\"@data-test-audio-recorder-pause\"],[\"audio-pause\",\"16px\",\"16px\",true]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"x-icon\",\"if\"]]",
    "moduleName": "audio/components/audio-recorder/button/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});