define("showbie-master-panel/components/panel-actions/x-sort/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ActionWrap
    @string={{t "Sort assignments by _{a1}_" a1=(t this.sortText)}}
    id="assignments-manage-list__sort-button"
    as |emphasizeText|
  >
    <em
      class="font-900 not-italic text-blue-600 hover:text-blue-700 focus:text-blue-700"
    >{{emphasizeText}}</em>
  </ActionWrap>
  */
  {
    "id": "GehfzEat",
    "block": "[[[8,[39,0],[[24,1,\"assignments-manage-list__sort-button\"]],[[\"@string\"],[[28,[37,1],[\"Sort assignments by _{a1}_\"],[[\"a1\"],[[28,[37,1],[[30,0,[\"sortText\"]]],null]]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"em\"],[14,0,\"font-900 not-italic text-blue-600 hover:text-blue-700 focus:text-blue-700\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]]]]]],[\"emphasizeText\"],false,[\"action-wrap\",\"t\"]]",
    "moduleName": "showbie-master-panel/components/panel-actions/x-sort/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});