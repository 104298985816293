define("audio/components/x-audio/component", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/runloop", "@ember/template", "moment", "audio/components/x-audio/template"], function (_exports, _component, _debug, _object, _evented, _runloop, _template, _moment, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-actions-hash, ember/no-classic-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-get, ember/require-super-in-lifecycle-hooks, ember/require-tagless-components */

  /**
   * How often the component ticks.
   * This determines the frequency of the current position updates.
   */
  const TICK_INTERVAL = 250;

  // standard audio events we add listeners for.
  const AUDIO_EVENTS = ['timeupdate', 'canplay', 'playing', 'pause', 'progress', 'ended'];

  /**
   * Base HTML Audio component
   *
   * @prop {string} url - Path to audio file or URL blob
   * @prop {string} mime - Audio file's mimetype
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
   * @see https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
   * @see https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Using_HTML5_audio_and_video#Error_handling
   */
  var _default = _component.default.extend(_evented.default, {
    layout: _template2.default,
    classNames: ['x-audio', 'post__audio__comment'],
    classNameBindings: ['isPlaying', 'isLoaded', 'error:post__audio--unsupported'],
    /**
     * Reference to parent pin (for sbe-pinned-note).
     * Required so we can attach a listener to pause the audio when the
     * pin is closed manually by the user.
     */
    pin: null,
    // bound properties for audio-specific events
    boundAudioEventHandler: null,
    boundAudioSeek: null,
    boundErrorHandler: null,
    /**
     * Normalize the public `mime` attribute.
     * This property can be bound to the `type` attribute on the
     * `audio` element.
     *
     * Old voice notes (from the iPad app) were saved with
     * `contentType=audio/m4a`. Here, we check for that specifically and
     * convert it to `audio/mp4`.
     *
     * @public
     */
    type: (0, _object.computed)('mime', function () {
      let type = (0, _object.get)(this, 'mime');
      if (type === 'audio/m4a') {
        type = 'audio/mp4';
      }
      return type;
    }),
    /**
     * A CSS width (%) representing the percent buffered.
     *
     * Usage: `style="{{bufferedStyle}}"`
     * NOTE: This will override any other inline style attributes
     *
     * @public
     * @return {String} similar to "width:57%;"
     */
    bufferedStyle: (0, _object.computed)('percentBuffered', function () {
      let width = (0, _object.get)(this, 'percentBuffered') || 0;
      return (0, _template.htmlSafe)(`width:${width}%;`); // htmlSafe confirmed
    }),

    /**
     * A CSS width (%) representing the current position of
     * the voice note, relative to it's length.
     *
     * Usage: `style="{{positionStyleWidth}}"`
     * NOTE: This will override any other inline style attributes
     *
     * @public
     * @return {String} similar to "width:57%;"
     */
    positionStyleWidth: (0, _object.computed)('position', function () {
      let width = (0, _object.get)(this, 'position') || 0;
      return (0, _template.htmlSafe)(`width:${width}%;`); // htmlSafe confirmed
    }),

    /**
     * A CSS left (%) representing the current position of
     * the voice note, relative to it's length.
     *
     * Usage: `style="{{positionStyleLeft}}"`
     * NOTE: This will override any other inline style attributes
     *
     * @public
     * @return {String} similar to "left:57%;"
     */
    positionStyleLeft: (0, _object.computed)('position', function () {
      let width = (0, _object.get)(this, 'position') || 0;
      return (0, _template.htmlSafe)(`left:${width}%;`); // htmlSafe confirmed
    }),

    didInsertElement() {
      this._setup();
    },
    willDestroyElement() {
      this._super(...arguments);
      if (!this) return;

      // throw out the local URL we've created
      if (this.url) {
        window.URL.revokeObjectURL(this.url);
      }

      // remove native event listeners
      AUDIO_EVENTS.forEach(event => {
        this.el?.removeEventListener(event, this.boundAudioEventHandler, false);
      });
      this.element?.querySelector('[data-audio-seek]')?.removeEventListener('click', this.boundAudioSeek, false);
      this.element?.querySelector('source')?.removeEventListener('error', this.boundErrorHandler, false);
    },
    actions: {
      toggle() {
        if ((0, _object.get)(this, 'isPlaying')) {
          this._pause();
        } else {
          this._play();
        }
      }
    },
    audioEventHandler(ev) {
      switch (ev.type) {
        case 'timeupdate':
          this._didTick();
          break;
        case 'canplay':
          this._canPlay();
          break;
        case 'playing':
          this._didPlay();
          break;
        case 'pause':
          this._didPause();
          break;
        case 'progress':
          this._didProgress();
          break;
        case 'ended':
          this._didEnd();
      }
    },
    errorHandler() {
      if (this._checkDestroying()) return;

      // if all sources have failed, set our error flag.
      if (this.el?.networkState === window.HTMLMediaElement.NETWORK_NO_SOURCE) {
        (0, _object.set)(this, 'error', true);
      }
    },
    _checkDestroying() {
      return (0, _object.get)(this, 'isDestroyed') || (0, _object.get)(this, 'isDestroying');
    },
    _setup() {
      if (this._checkDestroying()) {
        return;
      }
      let el = this.element.querySelector('audio');
      (0, _object.set)(this, 'el', el);
      (0, _object.set)(this, 'boundAudioEventHandler', this.audioEventHandler.bind(this));
      AUDIO_EVENTS.forEach(event => {
        el.addEventListener(event, this.boundAudioEventHandler, false);
      });

      // allow User to click on the scrubber to seek to that point in the clip.
      (0, _object.set)(this, 'boundAudioSeek', this.audioSeek.bind(this));
      this.element.querySelector('[data-audio-seek]').addEventListener('click', this.boundAudioSeek, false);

      // catch playback errors
      (0, _object.set)(this, 'boundErrorHandler', this.errorHandler.bind(this));
      this.element.querySelector('source').addEventListener('error', this.boundErrorHandler, false);

      // autoplay
      this._play();
    },
    audioSeek(e) {
      let domain = e.target.getBoundingClientRect().width;
      let point = e.offsetX;
      let range = this.el.duration;
      let position = point / domain;
      this.el.currentTime = position * range;
    },
    /*
     * Event handlers
     */
    _didPlay() {
      if (this._checkDestroying()) {
        return;
      }
      (0, _object.set)(this, 'isPlaying', true);
    },
    _didPause() {
      if (this._checkDestroying()) {
        return false;
      }
      (0, _object.set)(this, 'isPlaying', false);
    },
    _didTick() {
      if (this._checkDestroying()) {
        return false;
      }
      (0, _runloop.throttle)(this, this._tick, TICK_INTERVAL);
    },
    _canPlay() {
      if (this._checkDestroying()) {
        return;
      }
      this._tick();
      (0, _object.set)(this, 'isLoaded', true);
    },
    _didProgress() {
      let el = (0, _object.get)(this, 'el');
      let percentBuffered;
      if (!el.buffered.length) {
        return false;
      }
      if (this._checkDestroying()) {
        return false;
      }
      percentBuffered = el.buffered.end(0) / el.duration * 100;
      (0, _object.set)(this, 'percentBuffered', percentBuffered);
    },
    _didEnd() {
      (0, _object.setProperties)(this, {
        position: 100,
        isPlaying: false
      });
    },
    /**
     * @private
     */
    _pause() {
      // this.toggleProperty('isPlaying');
      let el = (0, _object.get)(this, 'el');
      if (!el.paused) {
        el.pause();
      }
    },
    /**
     * Note that HTMLMediaElement.play() returns a Promise in modern browser.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
     * @see https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
     * @private
     */
    _play() {
      // this.toggleProperty('isPlaying');
      let playPromise = (0, _object.get)(this, 'el').play();
      if (playPromise !== undefined) {
        return playPromise.then(() => {}).catch(reason => {
          (0, _debug.debug)(reason);
        });
      }
    },
    /**
     * @private
     */
    _tick() {
      let el = (0, _object.get)(this, 'el');
      let current = el.currentTime;
      let position = current / el.duration * 100;
      let currentTime = _moment.default.duration(current || 0, 'seconds');
      let seconds = currentTime.seconds();

      // prefix seconds with extra 0 if less than 10
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (this._checkDestroying()) {
        return;
      }
      (0, _object.setProperties)(this, {
        position,
        currentTime: `${currentTime.minutes()}:${seconds}`
      });
    }
  });
  _exports.default = _default;
});