define("torii/mixins/ui-service-mixin", ["exports", "@ember/runloop", "rsvp", "@ember/object/mixin", "@ember/object/evented", "torii/lib/uuid-generator", "torii/lib/popup-id-serializer", "torii/lib/parse-query-string", "torii/lib/assert", "torii/configuration"], function (_exports, _runloop, _rsvp, _mixin, _evented, _uuidGenerator, _popupIdSerializer, _parseQueryString, _assert, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WARNING_KEY = _exports.CURRENT_REQUEST_KEY = void 0;
  /* eslint-disable ember/no-new-mixins */

  const CURRENT_REQUEST_KEY = '__torii_request';
  _exports.CURRENT_REQUEST_KEY = CURRENT_REQUEST_KEY;
  const WARNING_KEY = '__torii_redirect_warning';
  _exports.WARNING_KEY = WARNING_KEY;
  function parseMessage(url, keys) {
    var parser = _parseQueryString.default.create({
      url: url,
      keys: keys
    });
    var data = parser.parse();
    return data;
  }
  var ServicesMixin = _mixin.default.create({
    init() {
      this._super(...arguments);
      this.remoteIdGenerator = this.remoteIdGenerator || _uuidGenerator.default;
    },
    // Open a remote window. Returns a promise that resolves or rejects
    // according to whether the window is redirected with arguments in the URL.
    //
    // For example, an OAuth2 request:
    //
    // popup.open('http://some-oauth.com', ['code']).then(function(data){
    //   // resolves with data.code, as from http://app.com?code=13124
    // });
    //
    // Services that use this mixin should implement openRemote
    //
    open(url, keys, options) {
      let service = this;
      let lastRemote = this.remote;
      let storageToriiEventHandler;
      return new _rsvp.Promise(function (resolve, reject) {
        if (lastRemote) {
          service.close();
        }
        var remoteId = service.remoteIdGenerator.generate();
        storageToriiEventHandler = function (storageEvent) {
          var remoteIdFromEvent = _popupIdSerializer.default.deserialize(storageEvent.key);
          if (remoteId === remoteIdFromEvent) {
            var data = parseMessage(storageEvent.newValue, keys);
            localStorage.removeItem(storageEvent.key);
            (0, _runloop.run)(function () {
              resolve(data);
            });
          }
        };
        var pendingRequestKey = _popupIdSerializer.default.serialize(remoteId);
        localStorage.setItem(CURRENT_REQUEST_KEY, pendingRequestKey);
        localStorage.removeItem(WARNING_KEY);
        service.openRemote(url, pendingRequestKey, options);
        service.schedulePolling();
        var onbeforeunload = window.onbeforeunload;
        window.onbeforeunload = function () {
          if (typeof onbeforeunload === 'function') {
            onbeforeunload();
          }
          service.close();
        };
        if (service.remote && !service.remote.closed) {
          service.remote.focus();
        } else {
          localStorage.removeItem(CURRENT_REQUEST_KEY);
          reject(new Error('remote could not open or was closed'));
          return;
        }
        service.one('didClose', function () {
          let hasWarning = localStorage.getItem(WARNING_KEY);
          if (hasWarning) {
            localStorage.removeItem(WARNING_KEY);
            let configuration = (0, _configuration.getConfiguration)();
            (0, _assert.default)(`[Torii] Using an OAuth redirect that loads your Ember App is deprecated and will be
              removed in a future release, as doing so is a potential security vulnerability.
              Hide this message by setting \`allowUnsafeRedirect: true\` in your Torii configuration.
          `, configuration.allowUnsafeRedirect);
          }
          var pendingRequestKey = localStorage.getItem(CURRENT_REQUEST_KEY);
          if (pendingRequestKey) {
            localStorage.removeItem(pendingRequestKey);
            localStorage.removeItem(CURRENT_REQUEST_KEY);
          }
          // If we don't receive a message before the timeout, we fail. Normally,
          // the message will be received and the window will close immediately.
          service.timeout = (0, _runloop.later)(service, function () {
            reject(new Error('remote was closed, authorization was denied, or an authentication message otherwise not received before the window closed.'));
          }, 100);
        });
        window.addEventListener('storage', storageToriiEventHandler);
      }).finally(function () {
        // didClose will reject this same promise, but it has already resolved.
        service.close();
        window.removeEventListener('storage', storageToriiEventHandler);
      });
    },
    close() {
      if (this.remote) {
        this.closeRemote();
        this.remote = null;
        this.trigger('didClose');
      }
      this.cleanUp();
    },
    cleanUp() {
      this.clearTimeout();
    },
    schedulePolling() {
      this.polling = (0, _runloop.later)(this, function () {
        this.pollRemote();
        this.schedulePolling();
      }, 35);
    },
    // Clear the timeout, in case it hasn't fired.
    clearTimeout() {
      (0, _runloop.cancel)(this.timeout);
      this.timeout = null;
    },
    stopPolling: (0, _evented.on)('didClose', function () {
      (0, _runloop.cancel)(this.polling);
    })
  });
  var _default = ServicesMixin;
  _exports.default = _default;
});