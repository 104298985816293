define("torii/adapters/application", ["exports", "rsvp", "@ember/object"], function (_exports, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */

  const ApplicationAdapter = _object.default.extend({
    open() {
      return new _rsvp.Promise(function () {
        throw new Error('The Torii adapter must implement `open` for a session to be opened');
      });
    },
    fetch() {
      return new _rsvp.Promise(function () {
        throw new Error('The Torii adapter must implement `fetch` for a session to be fetched');
      });
    },
    close() {
      return new _rsvp.Promise(function () {
        throw new Error('The Torii adapter must implement `close` for a session to be closed');
      });
    }
  });
  var _default = ApplicationAdapter;
  _exports.default = _default;
});