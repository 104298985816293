define("audio/components/audio-player/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "rsvp", "showbie-utils/mixins/post-asset-fetcher", "audio/components/audio-player/template"], function (_exports, _component, _object, _computed, _service, _rsvp, _postAssetFetcher, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-actions-hash, ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/no-mixins, ember/no-observers, ember/require-tagless-components */
  /**
   * Voicenote component to use for whenever you need a voicenote-style player.
   * Used namely on pinned voice notes, as well as voice notes added to post
   * listings.
   *
   * @class AudioPlayer
   * @extends Ember.Component
   * @uses ShowbieUtils/Mixins/PostAssetFetcher
   * @prop {DS.Model} model - Expects a belongsTo `signUrl` relationship
   * @example
   *   {{audio-player model=model}}
   */
  var _default = _component.default.extend(_postAssetFetcher.default, {
    intl: (0, _service.inject)(),
    layout: _template.default,
    classNames: ['audio-player'],
    classNameBindings: ['isLoaded:post--is-loaded'],
    attributeBindings: ['tabindex'],
    /**
     * A local Object URL.
     *
     * This gets set once we download the raw asset file using `assetUrl`.
     * It is the result of a `URL.createObjectURL(blob)` call.
     */
    localUrl: null,
    /**
     * Optional file URL for the voicenote - used instead of `model.signUrl`
     * if given.
     * @type {String}
     * @public
     */
    source: null,
    onWillLoad() {},
    onLoad() {},
    mime: (0, _computed.readOnly)('model.contentType'),
    /*
     * Do we think this browser can play this type of audio?
     */
    errorAudioFormat: (0, _object.computed)('filename', 'contentType', function () {
      // let ext = this.fileExtService.getExt(get(this, 'model.filename'), get(this, 'model.contentType'));
      // let canPlayType = window.Modernizr.audio[ext];
      // return !canPlayType;

      return false; // Modernizr is currently throwing false negatives, so for now allow for all
    }),

    // eslint-disable-next-line ember/no-observers
    displayError: (0, _object.observer)('errorAudioFormat', function () {
      let unsupported = (0, _object.get)(this, 'errorAudioFormat');
      if (unsupported) {
        (0, _object.set)(this, 'error', (0, _object.get)(this, 'intl').t('Unsupported audio file.'));
      }
    }),
    actions: {
      play() {
        // abort if we know file format is not supported in this browser.
        if ((0, _object.get)(this, 'errorAudioFormat')) {
          return false;
        }
        this.onWillLoad();
        this._fetchAssetURL().then(this._getBlob).then(blob => {
          let uri = window.URL.createObjectURL(blob);
          (0, _object.set)(this, 'localUrl', uri);
        }).then(() => {
          (0, _object.set)(this, 'isLoaded', true);
          this.send('onLoad');
        }).catch(() => {
          (0, _object.set)(this, 'error', (0, _object.get)(this, 'intl').t('Failed to load voice note.'));
        });
      },
      /**
       * Send action to bubble up and take effect on any parent components
       * (namely the post include in the folder index)
       *
       * NOTE: Possibly no longer used by anything.
       */
      onLoad() {
        this.onLoad();
      }
    },
    /**
     * _fetchAssetURL
     *
     * Grab the voicenote file URL for the given model. Either uses the source
     * attribute (if it exists), or falls back to the URL from `model.signUrl`.
     * @type {Promise}
     * @private
     */
    _fetchAssetURL() {
      let model = (0, _object.get)(this, 'model');
      let source = (0, _object.get)(this, 'source');
      if (source) {
        return _rsvp.default.resolve(source);
      }
      return (0, _object.get)(this, 'bindDownloadUrlTask').perform(model).then(signUrl => (0, _object.get)(signUrl, 'url'));
    }
  });
  _exports.default = _default;
});