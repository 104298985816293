define("showbie-master-panel/components/cell-meta/component", ["exports", "@ember/template-factory", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "showbie-utils/utils/assignment-counts", "showbie-utils/utils/topic-counts"], function (_exports, _templateFactory, _component, _object, _computed, _service, _assignmentCounts, _topicCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * @class CellMeta
   * @extends Ember.Component
   */
  let CellMeta = (_dec = (0, _computed.reads)('model.archivedDateStamp'), _dec2 = (0, _computed.reads)('model.isArchived'), _dec3 = (0, _computed.reads)('model.code'), _dec4 = (0, _computed.reads)('model.hasJoinableClassCode'), _dec5 = (0, _computed.reads)('model.constructor.modelName'), _dec6 = (0, _computed.reads)('model.totalAssignmentsAssigned'), _dec7 = (0, _computed.reads)('model.totalAssignmentsCollected'), _dec8 = (0, _computed.reads)('model.totalStudents'), _dec9 = (0, _object.computed)('modelName', 'topicsHierarchy'), _dec10 = (0, _object.computed)('archivedDateStamp', 'classCode', 'hasJoinableClassCode', 'isArchived', 'assignmentsAssigned', 'assignmentsCollected', 'totalStudents', 'topicChildCount'), (_class = class CellMeta extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "delorean", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", (0, _templateFactory.createTemplateFactory)(
      /*
        {{this.metaText}}
      */
      {
        "id": "z9ERw+AR",
        "block": "[[[1,[30,0,[\"metaText\"]]]],[],false,[]]",
        "moduleName": "/Users/keithkoshman/Dev/showbie/web/packages/showbie/showbie-master-panel/components/cell-meta/component.js",
        "isStrictMode": false
      }));
      _defineProperty(this, "model", null);
      _initializerDefineProperty(this, "archivedDateStamp", _descriptor3, this);
      _initializerDefineProperty(this, "isArchived", _descriptor4, this);
      _initializerDefineProperty(this, "classCode", _descriptor5, this);
      _initializerDefineProperty(this, "hasJoinableClassCode", _descriptor6, this);
      _initializerDefineProperty(this, "modelName", _descriptor7, this);
      _initializerDefineProperty(this, "assignmentsAssigned", _descriptor8, this);
      _initializerDefineProperty(this, "assignmentsCollected", _descriptor9, this);
      _initializerDefineProperty(this, "totalStudents", _descriptor10, this);
    }
    get topicChildCount() {
      if (this.modelName === 'topic') {
        const currentTopic = this.topicsHierarchy?.find(t => t.id === this.model.id);
        return currentTopic ? currentTopic.childrenIds.length : null;
      }
      return null;
    }
    get metaText() {
      let intl = this.intl;
      let modelName = this.modelName;
      let isArchived = this.isArchived;
      if (isArchived) {
        return intl.t('archived {a1}', {
          a1: this.delorean.timeago(this.archivedDateStamp)
        });
      }
      let totalStudents = this.totalStudents;
      if (!totalStudents && modelName === 'class') {
        if (this.hasJoinableClassCode) {
          return intl.t('class code {a1}', {
            a1: this.classCode
          });
        } else {
          return intl.t('registration closed');
        }
      }
      let assignmentsAssigned = this.assignmentsAssigned;
      let assignmentsCollected = this.assignmentsCollected;
      if (modelName === 'topic') {
        let assignments = assignmentsAssigned + assignmentsCollected;
        return (0, _topicCounts.getTopicMetaText)(assignments, this.topicChildCount, intl);
      }
      return (0, _assignmentCounts.getAssignmentCountsText)(assignmentsAssigned, assignmentsCollected, intl);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "delorean", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "archivedDateStamp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isArchived", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "classCode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasJoinableClassCode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "assignmentsAssigned", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "assignmentsCollected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "totalStudents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "topicChildCount", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "topicChildCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "metaText", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "metaText"), _class.prototype)), _class));
  _exports.default = CellMeta;
});