define("audio/components/audio-form/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "ember-concurrency", "ember-intl", "audio/components/audio-form/template"], function (_exports, _component, _object, _computed, _service, _emberConcurrency, _emberIntl, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-actions-hash, ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/require-computed-property-dependencies, ember/require-tagless-components */
  /**
   * @module audio
   */

  /**
   * Used to prevent upload progress indication from completing until
   * we're _really_ ready.
   * @const {number}
   */
  const MAX_UPLOAD_PROGRESS = 99;

  /**
   * @class AudioForm
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    intl: (0, _service.inject)(),
    notify: (0, _service.inject)(),
    store: (0, _service.inject)(),
    layout: _template.default,
    tagName: 'form',
    classNames: ['post-form__form'],
    file: null,
    isRecording: false,
    notFile: (0, _computed.not)('file'),
    /**
     * Whether the Save button is disabled or not.
     * @prop {boolean}
     */
    disableSave: (0, _computed.or)('notFile', 'isSaving', 'isRecording'),
    isSaving: (0, _computed.readOnly)('uploadTask.isRunning'),
    cancelWarningMessage: (0, _emberIntl.t)('Are you sure you want to discard your unsaved voice note?'),
    uploadProgress: (0, _object.computed)('token.progress', function () {
      let progress = (0, _object.get)(this, 'token.progress');
      if (!progress) {
        progress = 0;
      }

      /**
       * Cap the progress at our max upload progress, as there's still
       * saving of the annotation attachment that happens after this.
       */
      if (progress > MAX_UPLOAD_PROGRESS) {
        progress = MAX_UPLOAD_PROGRESS;
      }

      /**
       * As the progress re-sets to 0 after finished uploading (instead
       * of staying at 100), make sure the progress doesn't fall back
       * down once it's finished.
       */
      if (!progress && (0, _object.get)(this, 'isSaving')) {
        progress = MAX_UPLOAD_PROGRESS;
      }
      return progress;
    }),
    actions: {
      cancel() {
        const warningMessage = (0, _object.get)(this, 'cancelWarningMessage');
        if ((0, _object.get)(this, 'file') || (0, _object.get)(this, 'isRecording')) {
          (0, _object.get)(this, 'notify').confirm(warningMessage).then(() => this.onCancel()).catch(() => {});
        } else {
          this.onCancel();
        }
      },
      upload(file) {
        (0, _object.get)(this, 'uploadTask').perform(file);
      },
      onRecordingStarted() {
        (0, _object.set)(this, 'isRecording', true);
      },
      onRecordingStopped(file) {
        (0, _object.set)(this, 'isRecording', false);
        (0, _object.set)(this, 'file', file);
      }
    },
    uploadTask: (0, _emberConcurrency.task)(function* (file) {
      let token = yield (0, _object.get)(this, 'createTokenTask').perform(file);
      (0, _object.set)(this, 'token', token);
      yield this.onUpload(token);
    }),
    createTokenTask: (0, _emberConcurrency.task)(function* (file) {
      let {
        type: contentType,
        size: fileSize
      } = file;
      let fileName = this.intl.t('Voice Note');
      let token = (0, _object.get)(this, 'store').createRecord('token', {
        file,
        contentType,
        fileSize,
        fileName: `${fileName}.mp3`,
        fileSource: 'web_voice_recorder',
        voiceNote: true
      });
      return yield token;
    }).drop()
  });
  _exports.default = _default;
});