define("ember-svg-jar/inlined/plus-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.737 7.75a.75.75 0 00-1.5 0v9.345H7.75a.75.75 0 000 1.5h9.487v9.655a.75.75 0 001.5 0v-9.655h9.513a.75.75 0 000-1.5h-9.513V7.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36"
    }
  };
  _exports.default = _default;
});