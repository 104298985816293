define("ember-svg-jar/inlined/eye-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.052 10.001l.396.008a9.98 9.98 0 011.963.274l.353.092c2.117.585 4.196 1.823 6.343 3.773l.36.335a25.043 25.043 0 012.16 2.371l.098.133c.455.678.416 1.572-.116 2.181l-.169.222c-.178.225-.383.46-.588.694l-.362.4c-1.454 1.579-2.998 2.849-4.593 3.779l-.398.225c-1.187.648-2.304 1.075-3.406 1.3l-.415.076a10.29 10.29 0 01-1.655.135l-.398-.008a9.91 9.91 0 01-1.967-.275l-.369-.096c-2.091-.585-4.218-1.85-6.327-3.768l-.35-.322c-.906-.85-1.609-1.622-2.189-2.408l-.096-.14c-.414-.667-.382-1.46.097-2.104l.255-.33c.174-.216.356-.427.538-.632l.362-.402c1.457-1.58 3-2.848 4.594-3.775l.397-.225c1.184-.65 2.302-1.077 3.406-1.301a10.412 10.412 0 012.076-.212zm-.003 1.5l-.353.007c-.472.02-.946.077-1.42.175l-.32.072c-.964.236-1.958.656-3.028 1.281l-.366.22c-1.459.906-2.878 2.134-4.221 3.653l-.244.28a10.2 10.2 0 00-.467.581l-.044.068c-.073.133-.058.256.043.391l.203.268c.559.714 1.246 1.435 2.137 2.244l.361.321c1.925 1.676 3.834 2.749 5.682 3.197.659.16 1.33.24 2.008.24l.353-.007c.472-.02.946-.078 1.421-.174l.32-.072c.961-.235 1.955-.656 3.028-1.28l.366-.22c1.46-.909 2.879-2.137 4.219-3.655l.245-.28c.162-.189.32-.381.47-.582l.046-.064a.308.308 0 00-.029-.369l-.337-.411A21.726 21.726 0 0026.1 15.26l-.361-.321c-1.926-1.677-3.836-2.751-5.682-3.199l-.397-.086a8.543 8.543 0 00-1.611-.153zM18 14.25A3.755 3.755 0 0121.75 18 3.755 3.755 0 0118 21.75 3.755 3.755 0 0114.25 18 3.755 3.755 0 0118 14.25zm0 1.5c-1.24 0-2.25 1.01-2.25 2.25s1.01 2.25 2.25 2.25 2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});