define("showbie-master-panel/components/panel-actions/x-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showSelectAll}}
    {{t "Select all"}}
  {{else}}
    {{t "Deselect all"}}
  {{/if}}
  */
  {
    "id": "tl8p5hdt",
    "block": "[[[41,[30,0,[\"showSelectAll\"]],[[[1,\"  \"],[1,[28,[35,1],[\"Select all\"],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[\"Deselect all\"],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"t\"]]",
    "moduleName": "showbie-master-panel/components/panel-actions/x-select/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});