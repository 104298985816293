define("ember-cli-meta-tags/services/head-tags", ["exports", "@ember/object/internals", "@ember/service"], function (_exports, _internals, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const VALID_HEAD_TAGS = new Set(['base', 'link', 'meta', 'script', 'noscript', 'title']);
  let HeadTags = (_class = class HeadTags extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "headData", _descriptor, this);
    }
    // crawl up the active route stack and collect head tags
    collectHeadTags() {
      let tags = {};
      let currentHandlerInfos = this.router.targetState.routerJsState.routeInfos;
      currentHandlerInfos.forEach(handlerInfo => {
        Object.assign(tags, this._extractHeadTagsFromRoute(handlerInfo.route));
      });
      let tagArray = Object.keys(tags).map(id => tags[id]);
      this.headData.set('headTags', tagArray);
    }
    _extractHeadTagsFromRoute(route) {
      if (!route) {
        return {};
      }
      let headTags = route.headTags;
      if (!headTags) {
        return {};
      }
      if (typeof headTags === 'function') {
        headTags = headTags.apply(route);
      } else if (typeof headTags !== 'object') {
        // not recognized construct
        return {};
      }
      // convert headTags to object
      return this._buildTags(headTags);
    }

    // ensure all tags have a tagId and build object keyed by id
    _buildTags(headTagsArray) {
      let tagMap = {};
      headTagsArray.forEach(function (tagDefinition) {
        if (!tagDefinition || !VALID_HEAD_TAGS.has(tagDefinition.type)) {
          return;
        }
        let tagId = tagDefinition.tagId;
        if (!tagId) {
          tagId = (0, _internals.guidFor)(tagDefinition);
        }
        tagMap[tagId] = tagDefinition;
      });
      return tagMap;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = HeadTags;
});