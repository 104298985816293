define("showbie-master-panel/components/master-cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CellContainer
    @linkToRoute={{@linkToRoute}}
    @linkToModels={{@linkToModels}}
    @linkToQuery={{@linkToQuery}}
    @disabled={{@disabled}}
  >
    {{yield
      (hash
        disabled=@disabled
        symbol=(component "cell-symbol")
        content=(component "cell-content")
        status=(component "cell-status")
      )
    }}
  </CellContainer>
  */
  {
    "id": "YmjkEzfk",
    "block": "[[[8,[39,0],null,[[\"@linkToRoute\",\"@linkToModels\",\"@linkToQuery\",\"@disabled\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,[[28,[37,2],null,[[\"disabled\",\"symbol\",\"content\",\"status\"],[[30,4],[50,\"cell-symbol\",0,null,null],[50,\"cell-content\",0,null,null],[50,\"cell-status\",0,null,null]]]]]],[1,\"\\n\"]],[]]]]]],[\"@linkToRoute\",\"@linkToModels\",\"@linkToQuery\",\"@disabled\",\"&default\"],false,[\"cell-container\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "showbie-master-panel/components/master-cell/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});