define("showbie-master-panel/components/topic-colors/component", ["exports", "@ember/component", "@ember/object", "showbie-master-panel/components/topic-indicator/component", "showbie-master-panel/components/topic-colors/template"], function (_exports, _component, _object, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components */
  /**
   * @class TopicColors
   * @extends Ember.Component
   * @property {string} selectedColor
   */
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['topic-colors'],
    selectedColor: '',
    /**
     * Action to perform when color option changes. Sends the new color
     * string back as a param.
     *
     * @method onColorChange
     * @param {string} color
     * @required
     */
    onColorChange( /* color */) {},
    init() {
      this._super(...arguments);

      /**
       * Splits colour options into two distinct rows so the flex layout
       * works properly.
       */
      let colorOptions = Object.keys(_component2.TopicColors);
      let colorOptionsRow1 = colorOptions.slice(0, 5);
      let colorOptionsRow2 = colorOptions.slice(5);
      (0, _object.set)(this, 'colorOptions', [colorOptionsRow1, colorOptionsRow2]);
    }
  });
  _exports.default = _default;
});