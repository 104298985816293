define("showbie-master-panel/components/panel-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <MemoryScroll
    @key={{concat (url-key) (if @isManagingList "-managing")}}
    @class="panel__scrollable assignments"
  >
    {{yield}}
  </MemoryScroll>
  */
  {
    "id": "tWeRy09p",
    "block": "[[[8,[39,0],null,[[\"@key\",\"@class\"],[[28,[37,1],[[28,[37,2],null,null],[52,[30,1],\"-managing\"]],null],\"panel__scrollable assignments\"]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"@isManagingList\",\"&default\"],false,[\"memory-scroll\",\"concat\",\"url-key\",\"if\",\"yield\"]]",
    "moduleName": "showbie-master-panel/components/panel-content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});