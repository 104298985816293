define("showbie-master-panel/components/topic-colors/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.colorOptions as |options|}}
    <div class="color-options-row">
      {{#each options as |color|}}
        <label class="color-option">
          <input
            class="color-option-input"
            data-color-option-input
            type="radio"
            checked={{eq color @selectedColor}}
            name="iconColor"
            value={{color}}
            onChange={{action @onColorChange value="target.value"}}
          />
          <TopicIndicator @color={{color}} />
        </label>
      {{/each}}
    </div>
  {{/each}}
  */
  {
    "id": "VHH0jdOd",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"colorOptions\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"color-options-row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"label\"],[14,0,\"color-option\"],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"color-option-input\"],[14,\"data-color-option-input\",\"\"],[15,\"checked\",[28,[37,2],[[30,2],[30,3]],null]],[14,3,\"iconColor\"],[15,2,[30,2]],[15,\"onChange\",[28,[37,3],[[30,0],[30,4]],[[\"value\"],[\"target.value\"]]]],[14,4,\"radio\"],[12],[13],[1,\"\\n        \"],[8,[39,4],null,[[\"@color\"],[[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]],null]],[\"options\",\"color\",\"@selectedColor\",\"@onColorChange\"],false,[\"each\",\"-track-array\",\"eq\",\"action\",\"topic-indicator\"]]",
    "moduleName": "showbie-master-panel/components/topic-colors/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});