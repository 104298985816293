define("showbie-master-panel/components/panel-cell/component", ["exports", "@ember/template-factory", "@ember/component"], function (_exports, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components */
  var _default = _component.default.extend({
    layout: (0, _templateFactory.createTemplateFactory)(
    /*
      
        {{yield
          (hash
            indicators=(component "cell-element" key="indicators")
            meta=(component "cell-element" key="meta")
            metaIndicators=(component "cell-element" key="meta-indicators")
            symbol=(component "cell-element" key="symbol")
            title=(component "cell-element" key="title")
            titleIndicators=(component "cell-element" key="title-indicators")
          )
        }}
      
    */
    {
      "id": "PdJdE5FC",
      "block": "[[[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"indicators\",\"meta\",\"metaIndicators\",\"symbol\",\"title\",\"titleIndicators\"],[[50,\"cell-element\",0,null,[[\"key\"],[\"indicators\"]]],[50,\"cell-element\",0,null,[[\"key\"],[\"meta\"]]],[50,\"cell-element\",0,null,[[\"key\"],[\"meta-indicators\"]]],[50,\"cell-element\",0,null,[[\"key\"],[\"symbol\"]]],[50,\"cell-element\",0,null,[[\"key\"],[\"title\"]]],[50,\"cell-element\",0,null,[[\"key\"],[\"title-indicators\"]]]]]]]],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
      "moduleName": "/Users/keithkoshman/Dev/showbie/web/packages/showbie/showbie-master-panel/components/panel-cell/component.js",
      "isStrictMode": false
    }),
    classNames: ['panel-cell'],
    classNameBindings: ['isFresh', 'isDisabled']
  });
  _exports.default = _default;
});