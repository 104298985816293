define("showbie-master-panel/components/topic-indicator/component", ["exports", "@ember/component", "@ember/debug", "@ember/object", "showbie-master-panel/components/topic-indicator/template"], function (_exports, _component, _debug, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TopicOptions = _exports.TopicColors = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-get, ember/no-get-with-default, ember/require-tagless-components */
  /**
   * @module master-panel
   */

  /**
   * @enum {string}
   * @readonly
   */
  const TopicOptions = Object.freeze({
    assignments: 'assignments',
    classes: 'classes',
    discussion: 'discussion',
    portfolio: 'portfolio'
  });

  /**
   * @enum {string}
   * @readonly
   */
  _exports.TopicOptions = TopicOptions;
  const TopicColors = Object.freeze({
    DARKBLUE: 'topic--darkblue',
    LIGHTBLUE: 'topic--lightblue',
    GREEN: 'topic--green',
    YELLOW: 'topic--yellow',
    ORANGE: 'topic--orange',
    RED: 'topic--red',
    PINK: 'topic--pink',
    PURPLE: 'topic--purple',
    DARKGREY: 'topic--darkgrey',
    LIGHTGREY: 'topic--lightgrey'
  });

  /**
   * @class TopicIndicator
   * @extends Ember.Component
   * @prop {TopicOptions} topic
   * @prop {ColorOptions} color
   */
  _exports.TopicColors = TopicColors;
  const TopicIndicator = _component.default.extend({
    layout: _template.default,
    classNames: ['topic-indicator'],
    classNameBindings: ['colorClass'],
    /**
     * @type {boolean}
     * @private
     */
    'data-test-topic-indicator': true,
    icon: (0, _object.computed)('topic', function () {
      let topic = (0, _object.get)(this, 'topic');
      switch (topic) {
        case TopicOptions.assignments:
          return 'topic-assignments';
        case TopicOptions.classes:
          return 'topic-classes';
        case TopicOptions.discussion:
          return 'topic-discussion';
        case TopicOptions.portfolio:
          return 'topic-portfolio';
        default:
          return 'topic-folder';
      }
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    colorClass: (0, _object.computed)('color', 'topic', function () {
      let color = this.color || '';
      let topic = (0, _object.get)(this, 'topic');
      let colorOptions = Object.keys(TopicColors).map(k => k.toLowerCase());

      // special case for selected cell states
      if (color.toLowerCase() === 'white') {
        return 'topic--white';
      }
      switch (topic) {
        case TopicOptions.assignments:
          return 'topic--lightgrey';
        case TopicOptions.classes:
        case TopicOptions.discussion:
          return 'topic--darkblue';
        case TopicOptions.portfolio:
          return 'topic--yellow';
        default:
          if (colorOptions.includes(color.toLowerCase())) {
            return TopicColors[color];
          } else {
            let availableColors = colorOptions.map(c => `'${c}'`).join(', ');
            (false && !(false) && (0, _debug.assert)(`'${color}' is not a valid colour option. Available colours are: ${availableColors}`));
          }
      }
    })
  });
  var _default = TopicIndicator;
  _exports.default = _default;
});