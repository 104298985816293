define("@glimmer/component/-private/ember-component-manager", ["exports", "@ember/object", "@ember/destroyable", "@ember/component", "@ember/runloop", "@glimmer/component/-private/base-component-manager", "@glimmer/component/-private/component"], function (_exports, _object, _destroyable, _component, _runloop, _baseComponentManager, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const CAPABILITIES = true ? (0, _component.capabilities)('3.13', {
    destructor: true,
    asyncLifeCycleCallbacks: false,
    updateHook: false
  }) : (0, _component.capabilities)('3.4', {
    destructor: true,
    asyncLifeCycleCallbacks: false
  });
  function scheduledDestroyComponent(component) {
    if (component.isDestroyed) {
      return;
    }
    (0, _destroyable.destroy)(component);
    (0, _component2.setDestroyed)(component);
  }

  /**
   * This component manager runs in Ember.js environments and extends the base component manager to:
   *
   * 1. Properly destroy the component's associated `meta` data structure
   * 2. Schedule destruction using Ember's runloop
   */
  class EmberGlimmerComponentManager extends _baseComponentManager.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "capabilities", CAPABILITIES);
    }
    destroyComponent(component) {
      if (component.isDestroying) {
        return;
      }
      (0, _component2.setDestroying)(component);
      (0, _runloop.schedule)('actions', component, component.willDestroy);
      (0, _runloop.schedule)('destroy', this, scheduledDestroyComponent, component);
    }
  }
  // In Ember 3.12 and earlier, the updateComponent hook was mandatory.
  // As of Ember 3.13, the `args` object is stable and each property of the
  // object participates in the autotrack stack on its own. This means we do not
  // need to set the `args` property on the component instance to invalidate
  // tracked getters that rely on `args`, and therefore don't require the `updateComponent`
  // hook at all.
  if (!true) {
    EmberGlimmerComponentManager.prototype.updateComponent = function updateComponent(component, args) {
      let argSnapshot = args.named;
      if (false /* DEBUG */) {
        argSnapshot = Object.freeze(argSnapshot);
      }
      (0, _object.set)(component, 'args', argSnapshot);
    };
  }
  var _default = EmberGlimmerComponentManager;
  _exports.default = _default;
});