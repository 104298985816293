define("ember-svg-jar/inlined/button-icon-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M25.02 28.015c.31-.32.48-.747.48-1.203v-16.31h-15v16.312c0 .566.269 1.091.719 1.405.262.184.57.282.888.282h11.786a1.546 1.546 0 001.127-.485zM15.51 7.5a1.01 1.01 0 00-1.01 1.01V9h7v-.49a1.01 1.01 0 00-1.01-1.01h-4.98zM27 9h.75a.75.75 0 110 1.5H27v16.313a3.209 3.209 0 01-1.359 2.636 3.034 3.034 0 01-1.748.551H12.107a3.028 3.028 0 01-1.748-.552A3.208 3.208 0 019 26.812v-16.31h-.75a.75.75 0 010-1.5H13V8.51A2.512 2.512 0 0115.51 6h4.98A2.512 2.512 0 0123 8.51V9h4zm-13.191 5.642a.75.75 0 011.5 0v9.394a.75.75 0 01-1.5 0v-9.394zm4.191-.75a.75.75 0 00-.75.75v9.394a.75.75 0 001.5 0v-9.394a.75.75 0 00-.75-.75zm2.691 10.144v-9.394a.75.75 0 011.5 0v9.394a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});