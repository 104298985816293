define("showbie-live/mixins/live-component", ["exports", "@ember/object", "@ember/service", "@ember/array", "@ember/utils", "@ember/object/mixin"], function (_exports, _object, _service, _array, _utils, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */

  const {
    CompositeDisposable
  } = Rx;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = _mixin.default.create({
    firehose: (0, _service.inject)(),
    /**
     * @virtual Called after init to allow a component to subscribe to the firehose service
     * @protected
     * @param {Ember.service} firehose - the firehose service
     * @return {(Rx.Disposable[]|Rx.Disposable)} call dispose on distruction of the component to cleanup the subscription
     */
    setupFirehose( /* firehose */) {},
    init() {
      this._super(...arguments);
      let firehose = (0, _object.get)(this, 'firehose');
      let disposable = this.setupFirehose(firehose);
      if ((0, _array.isArray)(disposable) && 'filter' in disposable) {
        disposable = disposable.filter(item => (0, _utils.isPresent)(item));
      }
      if (disposable && 'disposables' in disposable) {
        while (disposable.remove(undefined)) {
          // heyyyyyy
        }
      }
      this.disposable = (0, _utils.isPresent)(disposable) ? new CompositeDisposable(disposable) : new CompositeDisposable();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.disposable.dispose();
    }
  });
  _exports.default = _default;
});