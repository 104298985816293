define("ember-svg-jar/inlined/eye-slash-icon-md", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.984 3.294a.75.75 0 011.032-1.088l4.367 4.137c.92-.434 1.827-.743 2.733-.914a9.967 9.967 0 014.051.06c2.11.473 4.13 1.595 6.113 3.26.945.792 1.695 1.526 2.275 2.256.23.241.378.55.429.888l.004.035.005.045.004.054.003.097v.043c-.003.063-.003.063-.016.183-.052.347-.2.654-.384.845a12.3 12.3 0 01-.728.815 19.396 19.396 0 01-3.389 2.849l3.533 3.346a.75.75 0 01-1.032 1.09l-19-18zm14.117 11.308a4.57 4.57 0 00-6.338-6.005l1.168 1.107a3.07 3.07 0 014.002 3.79l1.168 1.108zM2.12 10.24a20.098 20.098 0 012.632-2.324l3.042 2.883a4.569 4.569 0 006.217 5.89l1.696 1.606c-.614.235-1.223.413-1.831.527a9.95 9.95 0 01-4.05-.06c-2.11-.472-4.128-1.593-6.114-3.26-.946-.794-1.696-1.526-2.32-2.308-.521-.65-.521-1.487-.001-2.135.203-.254.411-.486.73-.819zm6.813 2.343c0-.22.023-.435.067-.642l3.806 3.605a3.072 3.072 0 01-3.873-2.963z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});