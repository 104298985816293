define("ember-svg-jar/inlined/reorder-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.326 16.25l.091.007c.296.044.531.303.576.63L21 17l-.006.102c-.04.33-.273.59-.568.64l-.1.008H3.675l-.092-.007c-.296-.045-.531-.303-.576-.631L3 16.999l.006-.102c.04-.329.273-.59.568-.639l.101-.008h16.651zm0-5l.091.007c.296.044.531.303.576.63L21 12l-.006.102c-.04.33-.273.59-.568.64l-.1.008H3.675l-.092-.007c-.296-.045-.531-.303-.576-.631L3 11.999l.006-.102c.04-.329.273-.59.568-.639l.101-.008h16.651zm0-5l.091.007c.296.044.531.303.576.63L21 7l-.006.102c-.04.33-.273.59-.568.64l-.1.008H3.675l-.092-.007c-.296-.045-.531-.303-.576-.631L3 6.999l.006-.102c.04-.329.273-.59.568-.639l.101-.008h16.651z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});