define("ember-cli-meta-tags/components/head-tags", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @headTags as |headTag|}}
    <HeadTag @headTag={{headTag}} />
  {{/each}}
  */
  {
    "id": "UB4FX7HL",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@headTag\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[\"@headTags\",\"headTag\"],false,[\"each\",\"-track-array\",\"head-tag\"]]",
    "moduleName": "ember-cli-meta-tags/components/head-tags.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});